<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <div v-for="(logs, invoice_id) in invoiceLogs" :key="invoice_id" class="accordion">
            <div class="accordion-header" @click="toggleAccordion(invoice_id)">
              <span class="invoice-header">
                <h3 class="invoice-id text-white">Invoice ID: {{ invoice_id }}</h3>
                <router-link
                    :to="{ name: 'OrderManagementPrint', params: { id: invoice_id } }"
                    class="view-icon"
                    title="View Invoice"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
              </span>
            </div>
            <div v-show="activeAccordion === invoice_id" class="accordion-body">
              <div v-for="log in logs" :key="log.id" class="log-card">
                <!--                <div class="log-header">-->
                <!--                  <span class="log-title">Cart ID: {{ log.cart_id }}</span>-->
                <!--                </div>-->
                <div class="log-details">
                  <div class="log-detail">
                    <label>Price:</label>
                    <span>{{ log.cart?.sale_price.toFixed(2) || 'N/A' }}</span>
                    <label>Quantity:</label>
                    <span>{{ log.cart?.quantity || 'N/A' }}</span>
                    <label>Product Title:</label>
                    <span>{{ log.cart?.product_detail?.title || 'N/A' }}</span>
                    <label>SKU:</label>
                    <span>{{ log.cart?.product_detail?.sku || 'N/A' }}</span>
                    <label>Total Commission:</label>
                    <span>{{ log.cart?.product_detail?.total_commission || 'N/A' }}</span>
                    <label>Affiliate Commission:</label>
                    <span>{{ log.cart?.product_detail?.affiliate_commission || 'N/A' }}</span>
                    <label>Sub Affiliate Commission:</label>
                    <span>{{ log.cart?.product_detail?.sub_affiliate_commission || 'N/A' }}</span>
                  </div>

                  <div class="log-detail">
                    <label>Old Value:</label>
                    <pre class="log-value">{{ formatJson(log.old_value) }}</pre>
                  </div>
                  <div class="log-detail">
                    <label>New Value:</label>
                    <pre class="log-value">{{ formatJson(log.new_value) }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>

import axiosInstance from '../../../../axiosInstance';

export default {

  data() {
    return {
      invoiceLogs: {},
      activeAccordion: null,
    };
  },
  created() {
    // Fetch invoice logs from the backend
    this.fetchInvoiceLogs();
  },
  methods: {
    fetchInvoiceLogs() {
      return axiosInstance
          .get(`api/invoice-logs`)
          .then(response => {
            this.invoiceLogs = response.data;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    toggleAccordion(invoice_id) {
      // Toggle accordion display
      this.activeAccordion = this.activeAccordion === invoice_id ? null : invoice_id;
    },
    formatJson(jsonString) {
      try {
        const jsonObject = JSON.parse(jsonString);
        return JSON.stringify(jsonObject, null, 2);
      } catch (e) {
        return jsonString; // If it's not a valid JSON string, just return it as is
      }
    }
  }
};
</script>

<style scoped>
/* Accordion Styles */
.accordion-header {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  margin-bottom: 5px;
  border: 1px solid #0056b3;
  border-radius: 5px;
}

.accordion-header:hover {
  background-color: #0056b3;
}

.accordion-body {
  padding: 15px;
  border-left: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  background-color: #f9f9f9;
}

/* Log Card Styles */
.log-card {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.log-header {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.log-details {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.log-detail {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.log-detail label {
  font-size: 0.9em;
  font-weight: bold;
  color: #666;
}

.log-value {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: monospace;
  white-space: pre-wrap;
}

.log-detail span {
  font-size: 1em;
  color: #333;
}

h3 {
  font-size: 1.2em;
  margin: 0;
}

.invoice-header {
  display: flex;
  align-items: center;
  gap: 15px; /* Space between elements */
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.invoice-id {
  font-size: 1.5em; /* Larger font for emphasis */
  margin: 0;
  color: white; /* Darker shade for readability */
}

.view-icon {
  font-size: 1.5em; /* Match size with invoice ID */
  color: black; /* Primary color for the icon */
  text-decoration: none; /* Remove default underline */
  transition: color 0.3s; /* Smooth hover effect */
}

.view-icon:hover {
  color: black; /* Darker blue on hover */
  cursor: pointer;
}

</style>

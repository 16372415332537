import {createRouter, createWebHistory} from "vue-router";

import StoreLayout from "../components/Layouts/StoreLay.vue";
import InvoiceStock from "../components/StoreStock/StoreStock.vue";
import CartInvoice from "../components/StoreStock/CartInvoice.vue";

import ProductStockLayout from "../components/Layouts/StockLayout.vue";
import ProductQuantityStock from "../components/ProductStock/ProductStock.vue";

import ColorView from "../components/Admin/pages/Color/Color.vue";
import EditColor from "../components/Admin/pages/Color/EditColor.vue";
import CreateColor from "../components/Admin/pages/Color/CreateColor.vue";

import SupplierView from "../components/Admin/pages/Supplier/SupplierView.vue";
import EditSupplier from "../components/Admin/pages/Supplier/EditSupplier.vue";
import CreateSupplier from "../components/Admin/pages/Supplier/CreateSupplier.vue";
import SupplierProductSupper from "../components/SuperAdmin/SupplierProduct.vue";

import SuperRefundProduct from "../components/SuperAdmin/RefundProduct.vue";
import SuperAdminDisabledProduct from "../components/SuperAdmin/DisabledProduct.vue";
import SuperAdminDisabledProductDetail from "../components/SuperAdmin/DisabledProductDetail.vue";
import SuperAdminUpdatedPrice from "../components/SuperAdmin/UpdatedPrice.vue";
import SuperAdminBrandPercent from "../components/SuperAdmin/BrandPercent.vue";
import SuperAdminSubAffiliatePercent from "../components/SuperAdmin/SubAffiliateChart.vue";
import SuperAdminIsNewProduct from "../components/SuperAdmin/IsNewProduct.vue";
import ProductWithoutDescription from "../components/SuperAdmin/NoDescriptionProduct.vue";
import SubAffiliateMedals from "../components/SuperAdmin/SubAffMedals.vue";
import JardeFormActive from "../components/SuperAdmin/JardeActive.vue";
import SelledQuantity from "../components/SuperAdmin/SelledQtyProduct.vue";
import ProductDetailLog from "../components/SuperAdmin/ProductDetailLog.vue"
import ProductDetailCommissions from "../components/SuperAdmin/ProductDetailCommissions.vue"
import GetPricesLogs from "../components/SuperAdmin/pricesLogs.vue"
// import ProdColorView from "../components/ProdAdmin/pages/Color/ProdColor.vue";
// import ProdEditColor from "../components/ProdAdmin/pages/Color/ProdEditColor.vue";
// import ProdCreateColor from "../components/ProdAdmin/pages/Color/ProdCreateColor.vue";

import ExportInv from "../components/Admin/pages/Orders/ExportData.vue";
import ProductKeyword from "../components/DataEntry/pages/allProductsEntry.vue";
import ProductImage from "../components/DataEntry/pages/ProductImage/UpdateImage.vue";
import MultiImage from "../components/DataEntry/pages/ProductMultiImage.vue";
import ExpiryDate from "../components/DataEntry/pages/ExpiryDate/ExpiryDate.vue";
import PdfDwonload from "../components/DataEntry/pages/pdfDwonload.vue";
import IndexDwonload from "../components/DataEntry/pages/allCatalogues.vue";
import MainProductIndex from "../components/DataEntry/pages/MainProduct.vue";
import ProductDetailPriceChange from "../components/DataEntry/pages/ProductDetailPriceChange.vue";
import InsertNullData from "../components/DataEntry/InsertNullData.vue";
import ProductStatusChange from "../components/DataEntry/pages/UpdateProductStatus.vue";
import ProductDetailStatusChange from "../components/DataEntry/pages/ProductDetailStatus.vue"

import allProductPoint from "../components/DataEntry/pages/productPoint/allProductsPoints.vue";

import TipsView from "../components/Admin/pages/Tips/TipsView.vue";
import CreateTips from "../components/Admin/pages/Tips/CreateTips.vue";
import EditTips from "../components/Admin/pages/Tips/EditTips.vue";

import AffiliateBrand from "../components/Affiliate/pages/Brands/AffiliateBrand.vue";
import AffiliateAddress from "../components/Affiliate/pages/Address/UserAddress.vue";

import AffiliateBundles from "../components/Affiliate/pages/AffBundle/AffiliateBundle.vue";
import AffiliateBundleView from "../components/Affiliate/pages/AffBundle/AffiliateBundleView.vue";

import AffiliateUserGift from "../components/Affiliate/pages/UserGift/AffiliateUserGift.vue";
import CreateUserGift from "../components/Affiliate/pages/UserGift/CreateUserGift.vue";

import AffiliateProductRate from "../components/Affiliate/pages/Rate/AffRate.vue";
import SubAffCom from "../components/Affiliate/pages/CommissionForSubAff/CommissionForSubAff.vue"

import DataEntryTipsView from "../components/DataEntry/pages/EntryTips/dataEntryTips.vue";
import DataEntryCreateTips from "../components/DataEntry/pages/EntryTips/createDataEntryTips.vue";
import DataEntryEditTips from "../components/DataEntry/pages/EntryTips/editDataEntryTips.vue";

import ForgotPassword from "../components/User/shared/ForgotPassword.vue";
import ResetPassword from "../components/User/shared/ResetPassword.vue";

import AffiliatePoints from "../components/Admin/pages/AffiliatePoints/AffiliatePoint.vue";
import AffiliateResultPoints from "../components/Admin/pages/AffiliatePoints/AffiliatePointResult.vue";

import BazarView from "../components/User/shared/BazarView.vue";
import AboutView from "../components/User/shared/AboutUs.vue";
import ContactView from "../components/User/shared/ContactView.vue";

import AdminExportNames from "../components/AdminExport/AdminName.vue";
import AdminExportNamesWithoutTarget from "../components/AdminExport/AdminNameWithoutTarget.vue";
import AdminExportResults from "../components/AdminExport/ExportRes.vue";
import AffExpPoint from "../components/AdminExport/Points/AffiliatePointResult.vue";
import BagView from "../components/Admin/pages/BagInvoice/BagView.vue";
import CreateBag from "../components/Admin/pages/BagInvoice/CreateBag.vue";
import EditBag from "../components/Admin/pages/BagInvoice/EditBag.vue";
import NewPrices from "../components/AdminExport/NewPrice.vue";
import AdminExportResultsWithoutTarget from "../components/AdminExport/ExportResWithoutTarget.vue";
import ProductCommission from "../components/AdminExport/ProductCommission";

import AffNames from "../components/Admin/pages/AffiliateResult/AffNames.vue";
import AffRes from "../components/Admin/pages/AffiliateResult/AffRes.vue";

import SuperAdminOrder from "../components/ProdAdmin/pages/SubAdminInvoices.vue";
import OldSuperAdminOrder from "../components/ProdAdmin/pages/NotVerifiedOrders.vue";
import SuperAdminPendingOrder from "../components/ProdAdmin/pages/SuperAdminPendingOrder.vue";
import SuperAdminClosedOrder from "../components/ProdAdmin/pages/SuperAdminClosedOrder.vue";
import SuperAdminShippedOrder from "../components/ProdAdmin/pages/SuperAdminShippedOrder.vue";
import SuperAdminDeliveredOrder from "../components/ProdAdmin/pages/SuperAdminDeliveredOrder.vue";
import BarcodeProduct from "../components/ProdAdmin/pages/BarcodeInvoice.vue";
import OrderPrepare from "../components/ProdAdmin/pages/OrderToPrepare.vue";
import OrderTracking from "../components/ProdAdmin/pages/OrderTracking.vue";
import OrderCompletedInvoices from "../components/ProdAdmin/pages/AramexCompleted.vue"
import AramexDeliveryInvoice from "../components/ProdAdmin/pages/AramexDeliveryInvoices.vue"

import BarcodeExport from "../components/User/shared/BarcodeExport.vue";
import BarcodeNumber from "../components/User/shared/BarcodeNumber.vue";
import SuperAdminExport from "../components/User/shared/ProdAdminExport.vue";

import AffPdfIndex from "../components/Affiliate/pages/Pdf/AffPdfIndex.vue";

import SizeView from "../components/Admin/pages/Size/Size.vue";
import EditSize from "../components/Admin/pages/Size/EditSize.vue";
import CreateSize from "../components/Admin/pages/Size/CreateSize.vue";

import DiscountView from "../components/Admin/pages/Discount/Discount.vue";
import EditDiscount from "../components/Admin/pages/Discount/EditDiscount.vue";
import CreateDiscount from "../components/Admin/pages/Discount/CreateDiscount.vue";

import ProductConfigurationView from "../components/Admin/pages/ProductConfiguration/ProductConfiguration.vue";
import CreateProductConfiguration from "../components/Admin/pages/ProductConfiguration/CreateProductConfiguration.vue";
import EditProductConfiguration from "../components/Admin/pages/ProductConfiguration/EditProductConfiguration.vue";

import GiftCard from "../components/Admin/pages/GiftCard/GiftCard.vue";
import CreateGiftCard from "../components/Admin/pages/GiftCard/CreateGiftCard.vue";
import EditGiftCard from "../components/Admin/pages/GiftCard/EditGiftCard.vue";

import ProdCatView from "../components/Admin/pages/ProdCat/ProdCatView.vue";
import CreateProdCat from "../components/Admin/pages/ProdCat/CreateProdCat.vue";
import EditProdCat from "../components/Admin/pages/ProdCat/EditProdCat.vue";

import BundleView from "../components/Admin/pages/Bundle/BundleView.vue";
import CreateBundle from "../components/Admin/pages/Bundle/CreateBundle.vue";
import EditBundle from "../components/Admin/pages/Bundle/EditBundle.vue";

import ProductBundleView from "../components/Admin/pages/ProductBundle/ProductBundle.vue";
import CreateProductBundle from "../components/Admin/pages/ProductBundle/CreateProductBundle.vue";
import EditProductBundle from "../components/Admin/pages/ProductBundle/EditProductBundle.vue";

import ProductDetailBundleView from "../components/Admin/pages/ProductDetailBundle/ProductDetailBundleView.vue";
import CreateProductDetailBundle from "../components/Admin/pages/ProductDetailBundle/CreateProductDetailBundle.vue";
import EditProductDetailBundle from "../components/Admin/pages/ProductDetailBundle/EditProductDetailBundle.vue";

import LoginCallback from "../components/User/shared/LoginCallback.vue";

import HomeBundleView from "../components/User/shared/HomeBundleView.vue";

import BrandDiscountView from "../components/Admin/pages/BrandDiscount/BrandDiscount.vue";
import EditBrandDiscount from "../components/Admin/pages/BrandDiscount/EditBrandDiscount.vue";
import CreateBrandDiscount from "../components/Admin/pages/BrandDiscount/CreateBrandDiscount.vue";

import AffiliateDiscountView from "../components/Admin/pages/AffiliateDiscount/AffiliateDiscount.vue";
import CreateAffiliateDiscount from "../components/Admin/pages/AffiliateDiscount/CreateAffiliateDiscount.vue";
import EditAffiliateDiscount from "../components/Admin/pages/AffiliateDiscount/EditAffiliateDiscount.vue";

import AffiliateUserDiscountView from "../components/Admin/pages/AffiliateUserDiscount/AffiliateUserDiscount.vue";
import CreateAffiliateUserDiscount
    from "../components/Admin/pages/AffiliateUserDiscount/CreateAffiliateUserDiscount.vue";
import EditAffiliateUserDiscount from "../components/Admin/pages/AffiliateUserDiscount/EditAffiliateUserDiscount.vue";

import AllOrderManagement from "../components/OrderManagement/ManagementOrders.vue";
import OrderManagementNote from "../components/OrderManagement/ManagementOrderNotes.vue";
import OrderManagementBarcode from "../components/OrderManagement/InvoiceBarcodeBoxes.vue"
import CompletedOrdersManagement from "../components/OrderManagement/CompletedOrderManagement.vue"
import ToAramexManagement from "../components/OrderManagement/SendToAramex.vue"
import ProdComm from "../components/OrderManagement/ProdComm.vue"
import ExportOrderCom from "../components/OrderManagement/ExportOrderCom.vue"
import ExportOrdersComNotComplete from "../components/OrderManagement/ExportOrdersNotComplete.vue"
import InvoiceEdit from "../components/OrderManagement/InvoiceEdit.vue"

import OrderManagementPrint from "../components/User/shared/ManagementOrderPrint.vue";

import ProductDiscountView from "../components/Admin/pages/ProductDiscount/ProductDiscount.vue";
import CreateProductDiscount from "../components/Admin/pages/ProductDiscount/CreateProductDiscount.vue";

import BrandView from "../components/Admin/pages/Brand/Brand.vue";
import EditBrand from "../components/Admin/pages/Brand/EditBrand.vue";
import CreateBrand from "../components/Admin/pages/Brand/CreateBrand.vue";

import BrandImagesView from "../components/Admin/pages/BrandImages/BrandImages.vue";
import CreateBrandImages from "../components/Admin/pages/BrandImages/CreateBrandImages.vue";

import AffiliateResult from "../components/Admin/pages/Orders/AffResult.vue";

import CategoryView from "../components/Admin/pages/Category/Category.vue";
import EditCategory from "../components/Admin/pages/Category/EditCategory.vue";
import CreateCategory from "../components/Admin/pages/Category/CreateCategory.vue";

import SubCategoryView from "../components/Admin/pages/SubCategory/SubCategory.vue";
import EditSubCategory from "../components/Admin/pages/SubCategory/EditSubCategory.vue";
import CreateSubCategory from "../components/Admin/pages/SubCategory/CreateSubCategory.vue";

import ChildCategoryView from "../components/Admin/pages/ChildCategory/ChildCategory.vue";
import EditChildCategory from "../components/Admin/pages/ChildCategory/EditChildCategory.vue";
import CreateChildCategory from "../components/Admin/pages/ChildCategory/CreateChildCategory.vue";

import ProductView from "../components/Admin/pages/Product/Product.vue";
import EditProduct from "../components/Admin/pages/Product/EditProduct.vue";
import CreateProduct from "../components/Admin/pages/Product/CreateProduct.vue";

import UserDiscount from "../components/Affiliate/pages/Discount/UserDiscount.vue";
import UserInvoice from "../components/Affiliate/pages/Discount/UserInvoice.vue";
import CreateUserDiscount from "../components/Affiliate/pages/Discount/CreateUserDiscount.vue";
import EditUserDiscount from "../components/Affiliate/pages/Discount/EditUserDiscount.vue";

import SaleView from "../components/Admin/pages/Sale/SaleView.vue";
import CreateSale from "../components/Admin/pages/Sale/CreateSale.vue";
import EditSale from "../components/Admin/pages/Sale/EditSale.vue";

import ProductSale from "../components/Admin/pages/ProductSale/ProductSale.vue";
import CreateProductSale from "../components/Admin/pages/ProductSale/CreateProductSale.vue";
import EditProductSale from "../components/Admin/pages/ProductSale/EditProductSale.vue";

import ProductDetailView from "../components/Admin/pages/ProductDetail/ProductDetail.vue";
import ProductConfigurationCreate from "../components/Admin/pages/ProductDetail/CreateProductConfiguration.vue";
import ProductFiltration from "../components/Admin/pages/ProductDetail/CreateProductFiltration.vue";
import ProductDetailSkuView from "../components/Admin/pages/ProductDetail/SkuProductDetail.vue";
import EditProductDetail from "../components/Admin/pages/ProductDetail/EditProductDetail.vue";
import CreateProductDetail from "../components/Admin/pages/ProductDetail/CreateProductDetail.vue";

import AdminAffiliateOrder from "../components/Admin/pages/Orders/AffiliateOrder.vue";

import AllOrder from "../components/Admin/pages/Orders/AllOrder.vue";
import PendingOrder from "../components/Admin/pages/Orders/PendingOrder.vue";
import ClosedOrder from "../components/Admin/pages/Orders/ClosedOrder.vue";
import MovedOrder from "../components/Admin/pages/Orders/MovedOrder.vue";
import RefundOrder from "../components/Admin/pages/Orders/CanceledOrder.vue";
import PendingProduct from "../components/Admin/pages/PendingProduct/PendingProduct.vue";
import PendingProductDetail from "../components/Admin/pages/PendingProduct/PendingProductDetail.vue";
import ViewOrder from "../components/Admin/pages/Orders/ViewOrder.vue";
import ExportInvoice from "../components/User/shared/ExportInvoice.vue";
import ExportInvoiceReturn from "../components/User/shared/ExportInvoiceReturn.vue";

import AttributeView from "../components/Admin/pages/Attribute/Attribute.vue";
import EditAttribute from "../components/Admin/pages/Attribute/EditAttribute.vue";
import CreateAttribute from "../components/Admin/pages/Attribute/CreateAttribute.vue";

import LogoView from "../components/Admin/pages/Logo/LogoView.vue";
import CreateLogo from "../components/Admin/pages/Logo/CreateLogo.vue";
import EditLogo from "../components/Admin/pages/Logo/EditLogo.vue";

import AttributeTypeView from "../components/Admin/pages/AttributeType/AttributeType.vue";
import EditAttributeType from "../components/Admin/pages/AttributeType/EditAttributeType.vue";
import CreateAttributeType from "../components/Admin/pages/AttributeType/CreateAttributeType.vue";

import SimpleDiscount from "../components/Admin/pages/UserDiscount/SimpleUserDiscount.vue";
import CreateSimpleDiscount from "../components/Admin/pages/UserDiscount/CreateSimpleDiscount.vue";
import EditSimpleDiscount from "../components/Admin/pages/UserDiscount/EditSimpleDiscount.vue";

import UserBrandDiscount from "../components/Admin/pages/UserBrandDiscount/UserBrandDiscount.vue";
import CreateUserBrandDiscount from "../components/Admin/pages/UserBrandDiscount/CreateUserBrandDiscount.vue";
import EditUserBrandDiscount from "../components/Admin/pages/UserBrandDiscount/EditUserBrandDiscount.vue";

import UserProductDiscount from "../components/Admin/pages/UserProductDiscount/UserProductDiscount.vue";
import CreateUserProductDiscount from "../components/Admin/pages/UserProductDiscount/CreateUserProductDiscount.vue";
import EditUserProductDiscount from "../components/Admin/pages/UserProductDiscount/EditUserProductDiscount.vue";

import FilterView from "../components/Admin/pages/Filter/Filter.vue";
import EditFilter from "../components/Admin/pages/Filter/EditFilter.vue";
import CreateFilter from "../components/Admin/pages/Filter/CreateFilter.vue";

import RefundView from "../components/Admin/pages/RefundProduct/RefundView.vue";
import RefundProductView from "../components/Admin/pages/RefundProduct/RefundProductView.vue";

import FilterTypeView from "../components/Admin/pages/FilterType/FilterType.vue";
import EditFilterType from "../components/Admin/pages/FilterType/EditFilterType.vue";
import CreateFilterType from "../components/Admin/pages/FilterType/CreateFilterType.vue";

import AffiliateReturnCart from "../components/Admin/pages/Return/AffiliateReturnCart.vue";
import AffiliateReturnInvoice from "../components/Admin/pages/Return/AffiliateReturnInvoice.vue";

import UserProductView from "../components/User/shared/ProductLayout.vue";
import UserLayout from "../components/Layouts/UserPage.vue";

import AffiliateUser from "../components/Affiliate/pages/User/AffiliateUser.vue";
import AffiliateCreateUser from "../components/Affiliate/pages/User/CreateAffiliateUser.vue";
import AffiliateEditUser from "../components/Affiliate/pages/User/EditAffiliateUser.vue";

import UserAffiliate from "../components/Affiliate/pages/SubAffiliate/AffiliateHierarchie.vue";
import CreateUserAffiliate from "../components/Affiliate/pages/SubAffiliate/CreateAffiliateHierarchie.vue";
import EditUserAffiliate from "../components/Affiliate/pages/SubAffiliate/EditAffiliateHierarchie.vue";

import AffiliateProductDiscount from "../components/Affiliate/pages/Sale/AffiliateProductSale.vue";
import SubInvoices from "../components/Affiliate/pages/SubInvoices/SubInvoices.vue";
import InvoicesUpdate from "../components/Affiliate/pages/CartUpdate/CartEdit.vue";

import ProductDescription from "../components/DataEntry/pages/ProductDescription.vue";

import AdminUser from "../components/Admin/pages/User/AdminUser.vue";
import CreateAdminUser from "../components/Admin/pages/User/CreateAdminUser.vue";
import EditAdminUser from "../components/Admin/pages/User/EditAdminUser.vue";

import SubAffiliateOrder from "../components/SouAffiliate/pages/Order/MakeOrder.vue";
import subAffiliateCart from "../components/SouAffiliate/pages/Cart/AffiliateCartView.vue";
import SubAffiliateInvoiceUpdate from "../components/SouAffiliate/pages/EditInvoice/InvoiceEditSub.vue"

import SliderView from "../components/Admin/pages/Slider/SliderView.vue";
import CreateSlider from "../components/Admin/pages/Slider/CreateSlider.vue";
import EditSlider from "../components/Admin/pages/Slider/EditSlider.vue";

import MakeOrder from "../components/Affiliate/pages/Order/MakeOrder.vue";
import AffiliateCart from "../components/Affiliate/pages/Cart/AffiliateCartView.vue";

import AffiliateRevenue from "../components/Affiliate/pages/Revenue/AffiliateRevenue.vue";
import AffiliateOrderDetails from "../components/User/shared/OrderDetail.vue";

import AffiliateAllOrders from "../components/SuperAffiliate/pages/Orders/allOrders.vue";
import AffiliateViewOrder from "../components/SuperAffiliate/pages/Orders/ViewOrder.vue";
import AllAffiliate from "../components/SuperAffiliate/pages/Affiliates/AllAffiliates.vue";
import AllUserAffiliate from "../components/SuperAffiliate/pages/Affiliates/UsersAffiliate.vue";
import UserAffiliateInvoice from "../components/SuperAffiliate/pages/Affiliates/UserAffiliateInvoice.vue";
import SuperAffiliateProduct from "../components/SuperAffiliate/pages/ProductAffiliate/SuperAffiliateProduct.vue";
import ChangeMinQty from "../components/SuperAffiliate/pages/ProductAffiliate/ChgMinQty.vue";
import UserAffiliateOrder from "../components/User/shared/UserAffilateOrder.vue";
import CreateAffiliate from "../components/SuperAffiliate/pages/Users/CreateAffiliate.vue";
import RequestAffiliate from "../components/SuperAffiliate/pages/Request/RequestAffiliate.vue";

import ProductSaleView from "../components/User/shared/ProductSale.vue";
import ProductSearch from "../components/User/shared/SearchedProduct.vue";
import SubCategory from "../components/User/shared/SubCategoryView.vue";
import ChildCategory from "../components/User/shared/ChildCategory.vue";
import ProductList from "../components/User/shared/ProductList.vue";
import ProductDetail from "../components/User/shared/ProductDetail.vue";
import AddToCart from "../components/User/shared/AddToCart.vue";
import AddToFavorite from "../components/User/shared/AddToFavorite.vue";
import LoginView from "../components/User/shared/LoginView.vue";
import RegisterView from "../components/User/shared/RegisterView.vue";
import AllCategories from "../components/User/shared/CategoryView.vue";
import Categories from "../components/User/shared/CategoryPage.vue";
import UserProfile from "../components/User/shared/userProfile.vue";
import GrandBazar from "../components/User/shared/GrandBazar.vue";
import HomeBrandView from "../components/User/shared/BrandDetail.vue";
import PrivacyPolicy from "../components/User/shared/PrivacyPolicy.vue";
import CustomiseDesign from "../components/User/shared/CustomiseDesign.vue";
import BrandDiscount from "../components/User/shared/ProductOnDiscount.vue";

import AdminLayout from "../components/Layouts/AdminPage.vue";
import AdminExportResult from "../components/Layouts/AdminResult.vue";
import OrderManagement from "../components/Layouts/OrderManagement.vue";

import SubAffBdl from "../components/SouAffiliate/pages/AffBundle/AffiliateBundle.vue"
import SubAffBdlView from "../components/SouAffiliate/pages/AffBundle/AffiliateBundleView.vue"

import SubAffiliateUserGift from "../components/SouAffiliate/pages/SubGift/SubAffiliateUserGift.vue"
import CreateUserSubGift from "../components/SouAffiliate/pages/SubGift/CreateSubUserGift.vue"
import UserCartGift from "../components/SouAffiliate/pages/UserCartGift/UserCartGift.vue"

import SubAffiliateRate from "../components/SouAffiliate/pages/SubRate/SubAffRate.vue"
import SubAaffiliateCommission from "../components/SouAffiliate/pages/Commissions/SubAffCommission.vue"
import ProductMedals from "../components/SouAffiliate/pages/MedalProduct/MedalProducts.vue"
import BazarProduct from "../components/SouAffiliate/pages/Bazar/BazarProducts.vue";

import SuperAdminView from "../components/SuperAdmin/SuperAdmin.vue";
import SuperViewMainProduct from "../components/SuperAdmin/UpdateMainProdPrice.vue";
import AffiliateOrderView from "../components/SuperAdmin/AffiliateProductOrder.vue";
import ProductMinQuantity from "../components/SuperAdmin/MinQuantity.vue";
import AffiliateChart from "../components/SuperAdmin/AffiliateChart.vue";
import HiddenProducts from "../components/SuperAdmin/HiddenProducts.vue";
import ProductWithoutImage from "../components/SuperAdmin/ProductWithoutImage.vue";
import InvoiceOrders from "../components/SuperAdmin/OrderInvoice.vue";
import SuperPendingOrder from "../components/SuperAdmin/InvoicePendingOrder.vue";
import SuperAllProduct from "../components/SuperAdmin/SuperAllProducts.vue";
import CreateSupplierProduct from "../components/SuperAdmin/CreateSupplier.vue";
import SupplierProduct from "../components/SuperAdmin/SupplierProduct.vue";
import SuperAdminAffiliateInvoices from "../components//SuperAdmin/AffiliatesInvoices.vue";

import InsertMainProduct from "../components/SouAdmin/pages/MainProduct/InsertMainProdut.vue";
import InsertMainProductDetail from "../components/SouAdmin/pages/ProductDetail/InsertProductDetail.vue";
import InsertOldData from "../components/SouAdmin/pages/OldProducts/OldProducts.vue";
import QuantityChange from "../components/SouAdmin/pages/QuantityChange/QuantityChange.vue";
import InsertColorData from "../components/SouAdmin/pages/InsertColor.vue";
import InsertSizeData from "../components/SouAdmin/pages/InsertSize.vue";
import InsertFormData from "../components/SouAdmin/pages/FormJarde/FormJarde.vue";
import JardeExport from "../components/SouAdmin/pages/FormJarde/JardeExport.vue";
import JardeExportData from "../components/SouAdmin/pages/FormJarde/ExportDataJarde.vue"
import OrderPreparation from "../components/SouAdmin/pages/OrderPreparation.vue"

import DisabledProduct from "../components/WareHouse/Farah/DisabledProduct.vue";
import DisabledProductMain from "../components/WareHouse/Farah/DisabledProductMain.vue";
import ReportProductCommission from "../components/WareHouse/Farah/ProductCommissionReport.vue"
import DisplayCost from "../components/WareHouse/Farah/CostDisplay.vue"
import InvoicesCart from "../components/WareHouse/Farah/allInvoicesCart.vue"
import InvoicesCartEdit from "../components/WareHouse/Farah/EditInvoicesCart.vue"
import DailyExport from "../components/WareHouse/Farah/DailyInvoiceTotal.vue"
import EditedInvoices from "../components/WareHouse/Farah/EditedInvoices.vue"

import DisabledDescriptionProduct from "../components/WareHouse/Jana/DisabledProductDescription.vue";
import DisabledDescriptionProductMain from "../components/WareHouse/Jana/DisabledProductDescriptionMain.vue";
import DisabledImageProduct from "../components/WareHouse/Jana/DisabledProductImage.vue";
import DisabledMultiImageProduct from "../components/WareHouse/Jana/DisabledMultiImage.vue";
import ProductPriceChange from "../components/WareHouse/Jana/PricesChange.vue";
import UpdateProductConfPosition from "../components/WareHouse/Jana/ProductPositions.vue";

import DisabledDesignProduct from "../components/WareHouse/Design/DisabledDesign.vue";

import DisabledFinalProduct from "../components/WareHouse/Bilal/FinalDisabledProduct.vue";
import DisabledFinalMainProduct from "../components/WareHouse/Bilal/FinalDisabledMainProduct.vue";

import newProducts from "../components/newProducts/productsNew.vue";
import oldProductsPage from "../components/newProducts/ProductsOld.vue";
import SubMedals from "../components/newProducts/MedalsSub.vue";

import getTargetChange from "../components/TargetChange/TargetChange.vue";
import getMainProduct from "../components/TargetChange/MainProductChange.vue";
import nonBrandProduct from "../components/TargetChange/NonBrandProduct.vue";
import AllAffiliates from "../components/TargetChange/AffiliateTargets.vue";
import AllAffiliatesTarget from "../components/TargetChange/AffiliateTargetCalc.vue";
import SubAffiliatesTargets from "../components/TargetChange/SubAffiliateCalc.vue";
import MedalsForSubAff from "../components/TargetChange/SubAffMedals.vue";
import CommissionsProducts from "../components/TargetChange/CommissionProduct.vue";

import getAccountingChange from "../components/Accounting/AccountingAcc.vue";
import getAccountingExpiryDate from "../components/Accounting/ProductExpiryDate.vue";
import getAccountingMinQty from "../components/Accounting/ProductMinQty.vue";

import getInsertedProductData from "../components/InsertedData/getInsertedProductData.vue";
import InsertMainProdutData from "../components/InsertedData/InsertedMainProductData.vue";
import insertProductDetailData from "../components/InsertedData/InsertedProductData.vue";
import getInsertedProductDetailData from "../components/InsertedData/getInsertedProductDetailData.vue";

import getDiffuserForm from "../components/DiffuserForm/DiffuserForm.vue";

import GetBoxesCheck from "../components/BoxesCheck/BoxesCheck.vue";

import GetQuantityCheck from "../components/QuantityCheck/MinimumQuantity.vue";
import WhatsappProduct from "../components/QuantityCheck/WhatsappProduct.vue";
import InvoicesCheck from "../components/QuantityCheck/InvoiceCheck.vue";
import OrderCheck from "../components/QuantityCheck/OrdersToRefund.vue";
import OrderReturnCheck from "../components/QuantityCheck/ReturnCheck/AffiliateReturnCartCheck.vue";
import OrderInvoiceReturnCheck from "../components/QuantityCheck/ReturnCheck/AffiliateReturnInvoiceCheck.vue";
import OrderInvoiceViewCheck from "../components/QuantityCheck/ViewOrderCheck.vue";
import RefundProductViewReturn from "../components/QuantityCheck/RefundProductReturn/RefundViewReturn.vue";
import RefundProductViewReturnProduct from "../components/QuantityCheck/RefundProductReturn/RefundProductViewReturn.vue";

import orderPrepare from "../components/Layouts/ProdAdminPage.vue";
import productsNewLayout from "../components/Layouts/NewProductsPage.vue";
import ProdAdminLayout from "../components/Layouts/SouAdminProd.vue";
import TargetChangeLayout from "../components/Layouts/TargetChangeLayout.vue";
import AccountingChangeLayout from "../components/Layouts/AccountingForm.vue";
import wareHouseAdminLayout from "../components/Layouts/FarahAdmin.vue";
import wareHouseDescriptionAdminLayout from "../components/Layouts/JanaDescription.vue";
import wareHouseDesignAdminLayout from "../components/Layouts/DesignAdmin.vue";
import wareHouseFinalAdminLayout from "../components/Layouts/FinalDisable.vue";
import SuperAdminLayout from "../components/Layouts/SuperAdminPage.vue";
import SuperAffiliate from "../components/Layouts/SuperAffiliate.vue";
import AffiliateLayout from "../components/Layouts/AffiliatePage.vue";
import SubAffiliateLayout from "../components/Layouts/SubAfiiliatePage.vue";
import DataEntryLayout from "../components/Layouts/DataEntryPage.vue";
import insertedDataLayout from "../components/Layouts/InsertedDataPage.vue";
import DiffuserFormLayout from "../components/Layouts/DiffuserLayout.vue";
import QuantityLayout from "../components/Layouts/QuantityLayout.vue";
import BoxesLayout from "../components/Layouts/CheckBx.vue";

import {useUserStore} from "../../store";
import axiosInstance from "../../axiosInstance";

import NotFound from "../components/User/shared/NotFound.vue";

const routes = [

    {
        path: "/chck-boxes",
        name: "BoxCheckPage",
        beforeEnter: BoxesGuard,
        component: BoxesLayout,
        children: [
            {
                path: "/get-boxes-check",
                name: "GetBoxesCheck",
                component: GetBoxesCheck
            },
        ]
    },

    {
        path: "/quantity-check",
        name: "quantityCheck",
        beforeEnter: QuantityGuard,
        component: QuantityLayout,
        children: [
            {
                path: "/get-quantity-check",
                name: "GetQuantityCheck",
                component: GetQuantityCheck
            },
            {
                path: "/get-whatsapp-product",
                name: "WhatsappProduct",
                component: WhatsappProduct
            },
            {
                path: "/invoices-check",
                name: "InvoicesCheck",
                component: InvoicesCheck
            },
            {
                path: "/orders-check",
                name: "OrderCheck",
                component: OrderCheck
            },
            {
                path: "/order-return-check",
                name: "OrderReturnCheck",
                component: OrderReturnCheck
            },
            {
                path: "/order-invoice-return-check",
                name: "OrderInvoiceReturnCheck",
                component: OrderInvoiceReturnCheck,
            },
            {
                path: "/order-invoice-view-check/:id",
                name: "OrderInvoiceViewCheck",
                component: OrderInvoiceViewCheck,
            },
            {
                path: "/refund-product-view-product",
                name: "RefundProductViewReturn",
                component: RefundProductViewReturn,
            },
            {
                path: "/refund-product-view-product-return/:id",
                name: "RefundProductViewReturnProduct",
                component: RefundProductViewReturnProduct,
            },
        ]
    },

    {
        path: "/target-change",
        name: "target-change",
        beforeEnter: targetChangeGuard,
        component: TargetChangeLayout,
        children: [
            {
                path: "/get-target-change",
                name: "getTargetChange",
                component: getTargetChange,
            },
            {
                path: "/get-main-product",
                name: "getMainProduct",
                component: getMainProduct,
            },
            {
                path: "/non-brand-product",
                name: "nonBrandProduct",
                component: nonBrandProduct,
            },
            {
                path: "/all-affiliates",
                name: "AllAffiliates",
                component: AllAffiliates
            },
            {
                path: "/all-affiliates-targets/:id",
                name: "AllAffiliatesTargets",
                component: AllAffiliatesTarget
            },
            {
                path: "/sub-affiliate-targets/:id",
                name: "SubAffiliatesTargets",
                component: SubAffiliatesTargets
            },
            {
                path: "/medals-for-sub-aff",
                name: "MedalsForSubAff",
                component: MedalsForSubAff
            },
            {
                path: "/commission-products",
                name: "CommissionsProducts",
                component: CommissionsProducts
            },
        ]
    },

    {
        path: "/accounting-form",
        name: "accounting-form",
        beforeEnter: AccountingChangeGuard,
        component: AccountingChangeLayout,
        children: [
            {
                path: "/get-accounting-form",
                name: "getAccountingChange",
                component: getAccountingChange,
            },
            {
                path: "/get-accounting-expiry-date",
                name: "getAccountingExpiryDate",
                component: getAccountingExpiryDate,
            },
            {
                path: "/get-accounting-min-qty",
                name: "getAccountingMinQty",
                component: getAccountingMinQty,
            },
        ]
    },

    {
        path: "/diffuser-form",
        name: "diffuser-form",
        beforeEnter: diffuserFormGuard,
        component: DiffuserFormLayout,
        children: [
            {
                path: "/get-diffuser-form",
                name: "getDiffuserForm",
                component: getDiffuserForm,
            },
        ]
    },

    {
        path: "/inserted-data",
        name: "inserted-data",
        beforeEnter: insertedDataGuard,
        component: insertedDataLayout,
        children: [
            {
                path: "/get-inserted-data",
                name: "getInsertedProductData",
                component: getInsertedProductData,
            },
            {
                path: "/get-inserted-product-detail-data",
                name: "getInsertedProductDetailData",
                component: getInsertedProductDetailData,
            },
            {
                path: "/inserted-main-product",
                name: "InsertMainProdutData",
                component: InsertMainProdutData,
            },
            {
                path: "/inserted-product-detail-data",
                name: "insertProductDetailData",
                component: insertProductDetailData,
            },
        ]
    },

    {
        path: "/prod-admin",
        name: "prod-admin",
        beforeEnter: prodAdminGuard,
        component: ProdAdminLayout,
        children: [
            {
                path: "/insert-main-product",
                name: "InsertMainProduct",
                component: InsertMainProduct,
            },
            {
                path: "/insert-main-product-detail",
                name: "InsertMainProductDetail",
                component: InsertMainProductDetail,
            },
            {
                path: "/insert-old-data",
                name: "InsertOldData",
                component: InsertOldData,
            },
            {
                path: "/insert-quantity-data",
                name: "QuantityChange",
                component: QuantityChange,
            },
            {
                path: "/insert-color-data",
                name: "InsertColorData",
                component: InsertColorData,
            },
            {
                path: "/insert-size-data",
                name: "InsertSizeData",
                component: InsertSizeData,
            },
            {
                path: "/insert-form-data",
                name: "InsertFormData",
                component: InsertFormData,
            },
            {
                path: "/jarde-export",
                name: "JardeExport",
                component: JardeExport
            },
            {
                path: "/jarde-export-data/:id",
                name: "JardeExportData",
                component: JardeExportData
            },
            {
                path: "/order-preparation",
                name: "OrderPreparation",
                component: OrderPreparation
            }
        ]
    },

    {
        path: "/products-new",
        name: "products-new",
        beforeEnter: productsNewGuard,
        component: productsNewLayout,
        children: [
            {
                path: "/new-products-page",
                name: "newProducts",
                component: newProducts
            },
            {
                path: "/old-products-page",
                name: "oldProductsPage",
                component: oldProductsPage
            },
            {
                path: "/sub-medals",
                name: "SubMedals",
                component: SubMedals
            }
        ]
    },

    {
        path: "/admin-jarde",
        name: "admin-jarde",
        beforeEnter: jardeAdminGuard,
        component: wareHouseAdminLayout,
        children: [
            {
                path: "/disabled-product",
                name: "DisabledProduct",
                component: DisabledProduct
            },
            {
                path: "/disabled-product-main",
                name: "DisabledProductMain",
                component: DisabledProductMain
            },
            {
                path: "/report-product-commission",
                name: "ReportProductCommission",
                component: ReportProductCommission
            },
            {
                path: "/display-cost",
                name: "DisplayCost",
                component: DisplayCost
            },
            {
                path: "/invoices-carts",
                name: "InvoicesCart",
                component: InvoicesCart
            },
            {
                path: "/invoices-carts-edit/:id",
                name: "InvoicesCartEdit",
                component: InvoicesCartEdit
            },
            {
                path: "/daily-export",
                name: "DailyExport",
                component: DailyExport
            },
            {
                path: "/edited-invoices",
                name: "EditedInvoices",
                component: EditedInvoices
            },
        ]
    },

    {
        path: "/description-jarde",
        name: "description-jarde",
        beforeEnter: descriptionAdminGuard,
        component: wareHouseDescriptionAdminLayout,
        children: [
            {
                path: "/disabled-description-product",
                name: "DisabledDescriptionProduct",
                component: DisabledDescriptionProduct
            },
            {
                path: "/disabled-description-product-main",
                name: "DisabledDescriptionProductMain",
                component: DisabledDescriptionProductMain
            },
            {
                path: "/disabled-image-product",
                name: "DisabledImageProduct",
                component: DisabledImageProduct
            },
            {
                path: "/disabled-multi-image-product/:id",
                name: "DisabledMultiImageProduct",
                component: DisabledMultiImageProduct
            },
            {
                path: "/prices-change",
                name: "ProductPriceChange",
                component: ProductPriceChange
            },
            {
                path: "/update-product-conf-position",
                name: "UpdateProductConfPosition",
                component: UpdateProductConfPosition
            },
        ]
    },

    {
        path: "/design-jarde",
        name: "design-jarde",
        beforeEnter: designAdminGuard,
        component: wareHouseDesignAdminLayout,
        children: [
            {
                path: "/disabled-design-product",
                name: "DisabledDesignProduct",
                component: DisabledDesignProduct
            },
        ]
    },

    {
        path: "/final-jarde",
        name: "final-jarde",
        beforeEnter: finalAdminGuard,
        component: wareHouseFinalAdminLayout,
        children: [
            {
                path: "/disabled-final-product",
                name: "DisabledFinalProduct",
                component: DisabledFinalProduct
            },
            {
                path: "/disabled-final-main-product",
                name: "DisabledFinalMainProduct",
                component: DisabledFinalMainProduct
            },

        ]
    },

    {
        path: "/sub-affiliate",
        name: "sub-affiliate",
        beforeEnter: SubAffiliateGuard,
        component: SubAffiliateLayout,
        children: [
            {
                path: "/sub-affiliate-invoice-update",
                name: "SubAffiliateInvoiceUpdate",
                component: SubAffiliateInvoiceUpdate,
            },
            {
                path: "/sub-affiliate-order",
                name: "SubAffiliateOrder",
                component: SubAffiliateOrder,
            },
            {
                path: "/sub-affiliate-cart",
                name: "subAffiliateCart",
                component: subAffiliateCart,
            },
            {
                path: "/sub-affiliate-bundle",
                name: "SubAffBdl",
                component: SubAffBdl,
            },
            {
                path: "/sub-affiliate-bundle-view/:id",
                name: "SubAffBdlView",
                component: SubAffBdlView
            },
            {
                path: "/sub-user-gift",
                name: "SubAffiliateUserGift",
                component: SubAffiliateUserGift
            },
            {
                path: "/create-user-sub-gift",
                name: "CreateUserSubGift",
                component: CreateUserSubGift
            },
            {
                path: "/user-cart-gift",
                name: "UserCartGift",
                component: UserCartGift
            },
            {
                path: "/sub-aff-rate",
                name: "SubAffiliateRate",
                component: SubAffiliateRate
            },
            {
                path: "/sub-affiliate-commission",
                name: "SubAffiliateCommission",
                component: SubAaffiliateCommission,
            },
            {
                path: "/product-medals",
                name: "ProductMedal",
                component: ProductMedals
            },
            {
                path: "/bazar-products",
                name: "BazarProduct",
                component: BazarProduct
            }
        ],
    },

    {
        path: "/order-management",
        name: "order-management",
        beforeEnter: OrderManagementData,
        component: OrderManagement,
        children: [
            {
                path: "/all-order-management",
                name: "AllOrderManagement",
                component: AllOrderManagement,
            },
            {
                path: "/order-management-note/:id",
                name: "OrderManagementNote",
                component: OrderManagementNote,
            },
            {
                path: "/order-management-barcode",
                name: "OrderManagementBarcode",
                component: OrderManagementBarcode
            },
            {
                path: "/completed-orders-management",
                name: "CompletedOrdersManagement",
                component: CompletedOrdersManagement
            },
            {
                path: "/to-aramex-management",
                name: "ToAramexManagement",
                component: ToAramexManagement
            },
            {
                path: "/product-comm",
                name: "ProductComm",
                component: ProdComm
            },
            {
                path: "/export-orders-com",
                name: "ExportOrderCom",
                component: ExportOrderCom
            },
            {
                path: "/export-orders-com-not-complete",
                name: "ExportOrdersComNotComplete",
                component: ExportOrdersComNotComplete
            },
            {
              path: "/invoice-edit/:id",
              name: "InvoiceEdit",
              component: InvoiceEdit
            }

        ],
    },

    {
        path: "/admin-export-results",
        name: "admin-export-result",
        beforeEnter: AdminExportResultss,
        component: AdminExportResult,
        children: [
            {
                path: "/admin-exports-name",
                name: "AdminExportNames",
                component: AdminExportNames,
            },
            {
                path: "/admin-exports-name-without-target",
                name: "AdminExportNamesWithoutTarget",
                component: AdminExportNamesWithoutTarget,
            },
            {
                path: "/admin-exports-res/:id",
                name: "AdminExportResults",
                component: AdminExportResults,
            },
            {
                path: "/admin-exports-res-without-target/:id",
                name: "AdminExportResultsWithoutTarget",
                component: AdminExportResultsWithoutTarget,
            },
            {
                path: "/export-points/:id",
                name: "AffExpPoint",
                component: AffExpPoint
            },
            {
                path: "/new-prices",
                name: "NewPrices",
                component: NewPrices
            },
            {
                path: "/product-commissions",
                name: "ProductCommission",
                component: ProductCommission
            }

        ],
    },

    {
        path: "/data-entry",
        name: "data-entry",
        beforeEnter: DataEntryAdmin,
        component: DataEntryLayout,
        children: [
            {
                path: "/product-description",
                name: "ProductDescription",
                component: ProductDescription,
            },

            {
                path: "/product-keywords",
                name: "ProductKeyword",
                component: ProductKeyword,
            },

            {
                path: "/multi-image/:id",
                name: "MultiImage",
                component: MultiImage
            },

            {
                path: "/product-point",
                name: "allProductPoint",
                component: allProductPoint
            },
            {
                path: "/product-image",
                name: "ProductImage",
                component: ProductImage
            },
            {
                path: "/expiry-date",
                name: "ExpiryDate",
                component: ExpiryDate
            },

            {
                path: "/pdf-download",
                name: "PdfDwonload",
                component: PdfDwonload
            },

            {
                path: "/index-download",
                name: "IndexDwonload",
                component: IndexDwonload
            },
            {
                path: "/index-main-product",
                name: "MainProductIndex",
                component: MainProductIndex
            },
            {
                path: "/product-detail-price-change",
                name: "ProductDetailPriceChange",
                component: ProductDetailPriceChange
            },
            {
                path: "/insert-null-data",
                name: "InsertNullData",
                component: InsertNullData
            },
            {
                path: "/product-status-change",
                name: "ProductStatusChange",
                component: ProductStatusChange
            },
            {
                path: "/product-detail-status-change/:id",
                name: "ProductDetailStatusChange",
                component: ProductDetailStatusChange
            }
        ],
    },

    {
        path: "/super-affiliate",
        name: "super-affiliate",
        beforeEnter: SuperAffiliateGuard,
        component: SuperAffiliate,
        children: [
            {
                path: "/request-affiliate",
                name: "RequestAffiliate",
                component: RequestAffiliate,
            },
            {
                path: "/create-affiliate",
                name: "CreateAffiliate",
                component: CreateAffiliate,
            },
            {
                path: "/affiliate-all-orders",
                name: "AffiliateAllOrders",
                component: AffiliateAllOrders,
            },
            {
                path: "/affiliate-view-order/:id",
                name: "AffiliateViewOrder",
                component: AffiliateViewOrder,
            },
            {
                path: "/all-affiliate",
                name: "AllAffiliate",
                component: AllAffiliate,
            },
            {
                path: "/user-affiliate/:id",
                name: "AllUserAffiliate",
                component: AllUserAffiliate,
            },
            {
                path: "/user-affiliate-invoice/:id",
                name: "UserAffiliateInvoice",
                component: UserAffiliateInvoice,
            },
            {
                path: "/super-affiliate-product",
                name: "SuperAffiliateProduct",
                component: SuperAffiliateProduct
            },
            {
                path: "/change-min-qty",
                name: "ChangeMinQty",
                component: ChangeMinQty
            },
        ],
    },

    {
        path: "/affiliate",
        name: "affiliate",
        beforeEnter: AffiliateGuard,
        component: AffiliateLayout,
        children: [
            {
                path: "/invoices-update",
                name: "InvoicesUpdate",
                component: InvoicesUpdate
            },
            {
                path: "/sub-invoices",
                name: "SubInvoices",
                component: SubInvoices
            },
            {
                path: "/affiliate-product-discount",
                name: "AffiliateProductDiscount",
                component: AffiliateProductDiscount
            },
            {
                path: "/make-order",
                name: "MakeOrder",
                component: MakeOrder,
            },
            {
                path: "/affiliate-cart",
                name: "AffiliateCart",
                component: AffiliateCart,
            },
            {
                path: "/affiliate-user",
                name: "AffiliateUser",
                component: AffiliateUser,
            },

            {
                path: "/create-affiliate-user",
                name: "CreateAffiliateUser",
                component: AffiliateCreateUser,
            },

            {
                path: "/edit-affiliate-user/:id",
                name: "EditAffiliateUser",
                component: AffiliateEditUser,
            },

            {
                path: "/user-affiliate",
                name: "UserAffiliate",
                component: UserAffiliate,
            },

            {
                path: "/create-user-affiliate",
                name: "CreateUserAffiliate",
                component: CreateUserAffiliate,
            },

            {
                path: "/edit-user-affiliate/:id",
                name: "EditUserAffiliate",
                component: EditUserAffiliate,
            },

            {
                path: "/user-discount",
                name: "UserDiscount",
                component: UserDiscount,
            },

            {
                path: "user-invoice/:id",
                name: "UserInvoice",
                component: UserInvoice,
            },

            {
                path: "/create-user-discount",
                name: "CreateUserDiscount",
                component: CreateUserDiscount,
            },

            {
                path: "/edit-user-discount/:id",
                name: "EditUserDiscount",
                component: EditUserDiscount,
            },

            {
                path: "/affiliate-revenue",
                name: "AffiliateRevenue",
                component: AffiliateRevenue,
            },
            {
                path: "/aff-pdf",
                name: "AffPdfIndex",
                component: AffPdfIndex
            },
            {
                path: "/affiliate-brand",
                name: "AffiliateBrand",
                component: AffiliateBrand
            },
            {
                path: "/affiliate-address",
                name: "AffiliateAddress",
                component: AffiliateAddress
            },
            {
                path: "/affiliate-bundles",
                name: "AffiliateBundles",
                component: AffiliateBundles
            },
            {
                path: "/affiliate-bundle-view/:id",
                name: "AffiliateBundleView",
                component: AffiliateBundleView
            },
            {
                path: "/user-gift",
                name: "AffiliateUserGift",
                component: AffiliateUserGift
            },
            {
                path: "/create-user-gift",
                name: "CreateUserGift",
                component: CreateUserGift
            },
            {
                path: "/affiliate-product-rate",
                name: "AffiliateProductRate",
                component: AffiliateProductRate
            },
            {
                path: "/sub-aff-com/:id",
                name: "SubAffCom",
                component: SubAffCom
            }
        ],
    },

    {
        path: "/superadmin",
        name: "superadmin",
        beforeEnter: SuperAdminSec,
        component: SuperAdminLayout,
        children: [
            {
                path: "/super-admin-affiliates-invoices",
                name: "SuperAdminAffiliateInvoices",
                component: SuperAdminAffiliateInvoices,
            },
            {
                path: "/super-view-main-product",
                name: "SuperViewMainProduct",
                component: SuperViewMainProduct,
            },
            {
                path: "/super-view",
                name: "SuperAdminView",
                component: SuperAdminView,
            },
            {
                path: "/affiliate-order-view/:id",
                name: "AffiliateOrderView",
                component: AffiliateOrderView,
            },
            {
                path: "/min-quantity",
                name: "ProductMinQuantity",
                component: ProductMinQuantity,
            },
            {
                path: "/affiliate-chart",
                name: "AffiliateChart",
                component: AffiliateChart,
            },
            {
                path: "/hidden-products",
                name: "HiddenProducts",
                component: HiddenProducts,
            },
            {
                path: "/products-without-image",
                name: "ProductWithoutImage",
                component: ProductWithoutImage,
            },
            {
                path: "/super-admin-orders-sup",
                name: "InvoiceOrders",
                component: InvoiceOrders,
            },
            {
                path: "/super-admin-pending-orders-sup",
                name: "SuperPendingOrder",
                component: SuperPendingOrder,
            },
            {
                path: "/super-all-products",
                name: "SuperAllProduct",
                component: SuperAllProduct
            },
            {
                path: "/create-supplier-product",
                name: "CreateSupplierProduct",
                component: CreateSupplierProduct
            },
            {
                path: "/supplier-product-super",
                name: "SupplierProductSupper",
                component: SupplierProductSupper
            },
            {
                path: "/super-refund-product",
                name: "SuperRefundProduct",
                component: SuperRefundProduct
            },
            {
                path: "/super-admin-disabled-product",
                name: "SuperAdminDisabledProduct",
                component: SuperAdminDisabledProduct
            },
            {
                path: "/super-admin-disabled-product-detail/:id",
                name: "SuperAdminDisabledProductDetail",
                component: SuperAdminDisabledProductDetail
            },
            {
                path: "/super-admin-updated-price",
                name: "SuperAdminUpdatedPrice",
                component: SuperAdminUpdatedPrice
            },
            {
                path: "/super-admin-get-brand-percent",
                name: "SuperAdminBrandPercent",
                component: SuperAdminBrandPercent
            },
            {
                path: "/super-admin-sub-affiliate-percent/:id",
                name: "SuperAdminSubAffiliatePercent",
                component: SuperAdminSubAffiliatePercent
            },
            {
                path: "/super-admin-is-new-product",
                name: "SuperAdminIsNewProduct",
                component: SuperAdminIsNewProduct
            },
            {
                path: "/product-without-description",
                name: "ProductWithoutDescription",
                component: ProductWithoutDescription
            },
            {
                path: "/sub-affiliate-medals",
                name: "SubAffiliateMedals",
                component: SubAffiliateMedals
            },
            {
                path: "/jarde-form-active",
                name: "JardeFormActive",
                component: JardeFormActive
            },
            {
                path: "/selled-quantity",
                name: "SelledQuantity",
                component: SelledQuantity
            },
            {
                path: "/product-detail-log/:id",
                name: "ProductDetailLog",
                component: ProductDetailLog
            },
            {
                path: "/product-detail-commissions",
                name: "ProductDetailCommissions",
                component: ProductDetailCommissions
            },
            {
                path: "/get-prices-logs",
                name: "GetPricesLogs",
                component: GetPricesLogs
            }
        ],
    },

    {
        path: "/store-panel",
        name: "storepanel",
        beforeEnter: StorePanelSec,
        component: StoreLayout,
        // beforeEnter: OrderAdminGuard,
        children: [
            {
                path: "/invoices-store",
                name: "InvoiceStock",
                component: InvoiceStock,
            },
            {
                path: "/cart-invoice/:id",
                name: "CartInvoice",
                component: CartInvoice
            }
        ],
    },

    {
        path: "/product-stock",
        name: "productstock",
        beforeEnter: StockPanelSec,
        component: ProductStockLayout,
        // beforeEnter: OrderAdminGuard,
        children: [
            {
                path: "/product-quantity-stock",
                name: "ProductQuantityStock",
                component: ProductQuantityStock,
            },
        ],
    },

    {
        path: "/prodadmin",
        name: "prodadmin",
        component: orderPrepare,
        // beforeEnter: OrderAdminGuard,
        children: [
            {
                path: "/super-admin-orders",
                name: "SuperAdminOrder",
                component: SuperAdminOrder,
            },
            {
                path: "/old-super-admin-orders",
                name: "OldSuperAdminOrder",
                component: OldSuperAdminOrder,
            },
            {
                path: "/super-admin-pending-orders",
                name: "SuperAdminPendingOrder",
                component: SuperAdminPendingOrder,
            },
            {
                path: "/super-admin-closed-orders",
                name: "SuperAdminClosedOrder",
                component: SuperAdminClosedOrder,
            },
            {
                path: "/super-admin-shipped-orders",
                name: "SuperAdminShippedOrder",
                component: SuperAdminShippedOrder,
            },
            {
                path: "/super-admin-delivered-orders",
                name: "SuperAdminDeliveredOrder",
                component: SuperAdminDeliveredOrder,
            },
            {
                path: "/barcode-product",
                name: "BarcodeProduct",
                component: BarcodeProduct,
            },

            {
                path: "/prepare-order",
                name: "OrderPrepare",
                component: OrderPrepare,
            },
            {
                path: "/order-tracking",
                name: "OrderTracking",
                component: OrderTracking,
            },
            {
                path: "/aramex-delivery-invoice",
                name: "AramexDeliveryInvoice",
                component: AramexDeliveryInvoice
            },
            {
                path: "/order-completed-invoice/:id",
                name: "OrderCompletedInvoices",
                component: OrderCompletedInvoices
            }

        ],
    },

    {
        path: "/admin",
        name: "admin",
        component: AdminLayout,
        beforeEnter: superadminGuard,
        children: [
            {
                path: "/affiliate-return-cart",
                name: "AffiliateReturnCart",
                component: AffiliateReturnCart
            },
            {
                path: "/affiliate-return-invoice",
                name: "AffiliateReturnInvoice",
                component: AffiliateReturnInvoice
            },
            {
                path: "/affiliate-point-result/:id",
                name: "AffiliateResultPoints",
                component: AffiliateResultPoints
            },
            {
                path: "/affiliate-points",
                name: "AffiliatePoints",
                component: AffiliatePoints
            },
            {
                path: "/tips-view",
                name: "TipsView",
                component: TipsView
            },
            {
                path: "/create-tips",
                name: "CreateTips",
                component: CreateTips,
            },
            {
                path: "/edit-tips/:id",
                name: "EditTips",
                component: EditTips
            },
            {
                path: "/gift-card",
                name: "GiftCard",
                component: GiftCard
            },
            {
                path: "/create-card",
                name: "CreateGiftCard",
                component: CreateGiftCard
            },
            {
                path: "/edit-card/:id",
                name: "EditCardGift",
                component: EditGiftCard
            },
            {
                path: "/aff-names",
                name: "AffNames",
                component: AffNames
            },
            {
                path: "/aff-res/:id",
                name: "AffRes",
                component: AffRes
            },
            {
                path: "/affiliate-result/:id",
                name: "AffiliateResult",
                component: AffiliateResult
            },
            {
                path: "/bag-view",
                name: "BagView",
                component: BagView
            },
            {
                path: "/edit-bag/:id",
                name: "EditBag",
                component: EditBag
            },
            {
                path: "/create-bag",
                name: "CreateBag",
                component: CreateBag
            },
            {
                path: "/export-inv/:id",
                name: "ExportInv",
                component: ExportInv
            },
            {
                path: "/admin-affiliate-order",
                name: "AdminAffiliateOrder",
                component: AdminAffiliateOrder,
            },
            {
                path: "/productbundle",
                name: "ProductBundleView",
                component: ProductBundleView,
            },
            {
                path: "/edit-productbundle/:id",
                name: "EditProductBundle",
                component: EditProductBundle,
            },
            {
                path: "/create-productbundle",
                name: "CreateProductBundle",
                component: CreateProductBundle,
            },

            {
                path: "/productdetailbundle",
                name: "ProductDetailBundleView",
                component: ProductDetailBundleView,
            },
            {
                path: "/edit-productdetailbundle/:id",
                name: "EditProductDetailBundle",
                component: EditProductDetailBundle,
            },
            {
                path: "/create-productdetailbundle",
                name: "CreateProductDetailBundle",
                component: CreateProductDetailBundle,
            },

            {
                path: "/simple-discount",
                name: "SimpleDiscount",
                component: SimpleDiscount,
            },
            {
                path: "/edit-simple-discount/:id",
                name: "EditSimpleDiscount",
                component: EditSimpleDiscount,
            },
            {
                path: "/create-simple-discount",
                name: "CreateSimpleDiscount",
                component: CreateSimpleDiscount,
            },

            {
                path: "/user-brand-discount",
                name: "UserBrandDiscount",
                component: UserBrandDiscount,
            },
            {
                path: "/edit-user-brand-discount/:id",
                name: "EditUserBrandDiscount",
                component: EditUserBrandDiscount,
            },
            {
                path: "/create-user-brand-discount",
                name: "CreateUserBrandDiscount",
                component: CreateUserBrandDiscount,
            },

            {
                path: "/user-product-discount",
                name: "UserProductDiscount",
                component: UserProductDiscount,
            },
            {
                path: "/edit-user-product-discount/:id",
                name: "EditUserProductDiscount",
                component: EditUserProductDiscount,
            },
            {
                path: "/create-user-product-discount",
                name: "CreateUserProductDiscount",
                component: CreateUserProductDiscount,
            },

            {
                path: "/bundle",
                name: "BundleView",
                component: BundleView,
            },
            {
                path: "/create-bundle",
                name: "CreateBundle",
                component: CreateBundle,
            },
            {
                path: "/edit-bundle/:id",
                name: "EditBundle",
                component: EditBundle,
            },

            {
                path: "/slider",
                name: "SliderView",
                component: SliderView,
            },
            {
                path: "/create-slider",
                name: "CreateSlider",
                component: CreateSlider,
            },
            {
                path: "/edit-slider/:id",
                name: "EditSlider",
                component: EditSlider,
            },
            {
                path: "/product-conf",
                name: "ProductConfigurationView",
                component: ProductConfigurationView,
            },
            {
                path: "/create-product-conf",
                name: "CreateProductConfiguration",
                component: CreateProductConfiguration,
            },
            {
                path: "/edit-product-conf/:id",
                name: "EditProductConfiguration",
                component: EditProductConfiguration,
            },
            {
                path: "/admin-user",
                name: "AdminUser",
                component: AdminUser,
            },
            {
                path: "/create-admin-user",
                name: "CreateAdminUser",
                component: CreateAdminUser,
            },
            {
                path: "/edit-admin-user/:id",
                name: "EditAdminUser",
                component: EditAdminUser,
            },

            {
                path: "/sale",
                name: "SaleView",
                component: SaleView,
            },
            {
                path: "/create-sale",
                name: "CreateSale",
                component: CreateSale,
            },
            {
                path: "/edit-sale/:id",
                name: "EditSale",
                component: EditSale,
            },
            {
                path: "/refund",
                name: "RefundView",
                component: RefundView,
            },
            {
                path: "/refund-product-view/:id",
                name: "RefundProductView",
                component: RefundProductView,
            },

            {
                path: "/product-sale",
                name: "ProductSale",
                component: ProductSale,
            },
            {
                path: "/product-create-sale",
                name: "CreateProductSale",
                component: CreateProductSale,
            },
            {
                path: "/product-edit-sale/:id",
                name: "EditProductSale",
                component: EditProductSale,
            },
            {
                path: "/logo-view",
                name: "LogoView",
                component: LogoView,
            },
            {
                path: "/edit-logo/:id",
                name: "EditLogo",
                component: EditLogo,
            },
            {
                path: "/create-logo",
                name: "CreateLogo",
                component: CreateLogo,
            },

            {
                path: "/color",
                name: "ColorView",
                component: ColorView,
            },
            {
                path: "/create-color",
                name: "CreateColor",
                component: CreateColor,
            },
            {
                path: "/edit-color/:id",
                name: "EditColor",
                component: EditColor,
            },

            {
                path: "/supplier",
                name: "SupplierView",
                component: SupplierView,
            },
            {
                path: "/supplier-product",
                name: "SupplierProduct",
                component: SupplierProduct,
            },
            {
                path: "/create-supplier",
                name: "CreateSupplier",
                component: CreateSupplier,
            },
            {
                path: "/edit-supplier/:id",
                name: "EditSupplier",
                component: EditSupplier,
            },

            {
                path: "/category",
                name: "CategoryView",
                component: CategoryView,
            },
            {
                path: "/create-category",
                name: "CreateCategory",
                component: CreateCategory,
            },
            {
                path: "/edit-category/:id",
                name: "EditCategory",
                component: EditCategory,
            },

            {
                path: "/subcategory",
                name: "SubCategoryView",
                component: SubCategoryView,
            },
            {
                path: "/create-subcategory",
                name: "CreateSubCategory",
                component: CreateSubCategory,
            },
            {
                path: "/edit-subcategory/:id",
                name: "EditSubCategory",
                component: EditSubCategory,
            },

            {
                path: "/childcategory",
                name: "ChildCategoryView",
                component: ChildCategoryView,
            },
            {
                path: "/create-childcategory",
                name: "CreateChildCategory",
                component: CreateChildCategory,
            },
            {
                path: "/edit-childcategory/:id",
                name: "EditChildCategory",
                component: EditChildCategory,
            },

            {
                path: "/size",
                name: "SizeView",
                component: SizeView,
            },
            {
                path: "/create-size",
                name: "CreateSize",
                component: CreateSize,
            },
            {
                path: "/edit-size/:id",
                name: "EditSize",
                component: EditSize,
            },

            {
                path: "/discount",
                name: "DiscountView",
                component: DiscountView,
            },
            {
                path: "/create-discount",
                name: "CreateDiscount",
                component: CreateDiscount,
            },
            {
                path: "/edit-discount/:id",
                name: "EditDiscount",
                component: EditDiscount,
            },

            {
                path: "/brand-discount",
                name: "BrandDiscountView",
                component: BrandDiscountView,
            },
            {
                path: "/create-brand-discount",
                name: "CreateBrandDiscount",
                component: CreateBrandDiscount,
            },
            {
                path: "/edit-brand-discount/:id",
                name: "EditBrandDiscount",
                component: EditBrandDiscount,
            },

            {
                path: "/prodcat",
                name: "ProdCatView",
                component: ProdCatView,
            },
            {
                path: "/create-prodcat",
                name: "CreateProdCat",
                component: CreateProdCat,
            },
            {
                path: "/edit-prodcat/:id",
                name: "EditProdCat",
                component: EditProdCat,
            },

            {
                path: "/affiliate-discount",
                name: "AffilaiteDiscountView",
                component: AffiliateDiscountView,
            },
            {
                path: "/create-affiliate-discount",
                name: "CreateAffiliateDiscount",
                component: CreateAffiliateDiscount,
            },
            {
                path: "/edit-affiliate-discount/:id",
                name: "EditAffiliateDiscount",
                component: EditAffiliateDiscount,
            },

            {
                path: "/affiliate-user-discount",
                name: "AffilaiteUserDiscountView",
                component: AffiliateUserDiscountView,
            },
            {
                path: "/create-affiliate-user-discount",
                name: "CreateAffiliateUserDiscount",
                component: CreateAffiliateUserDiscount,
            },
            {
                path: "/edit-affiliate-user-discount/:id",
                name: "EditAffiliateUserDiscount",
                component: EditAffiliateUserDiscount,
            },

            {
                path: "/product-discount",
                name: "ProductDiscountView",
                component: ProductDiscountView,
            },
            {
                path: "/create-product-discount",
                name: "CreateProductDiscount",
                component: CreateProductDiscount,
            },

            {
                path: "/brand",
                name: "BrandView",
                component: BrandView,
            },
            {
                path: "/create-brand",
                name: "CreateBrand",
                component: CreateBrand,
            },
            {
                path: "/edit-brand/:id",
                name: "EditBrand",
                component: EditBrand,
            },

            {
                path: "/brand-images",
                name: "BrandImagesView",
                component: BrandImagesView,
            },
            {
                path: "/create-brand-image",
                name: "CreateBrandImages",
                component: CreateBrandImages,
            },

            {
                path: "/product",
                name: "ProductView",
                component: ProductView,
            },
            {
                path: "/create-product",
                name: "CreateProduct",
                component: CreateProduct,
            },
            {
                path: "/edit-product/:id",
                name: "EditProduct",
                component: EditProduct,
            },

            {
                path: "/productdetail",
                name: "ProductDetailView",
                component: ProductDetailView,
            },
            {
                path: "/productdetailsku",
                name: "ProductDetailSkuView",
                component: ProductDetailSkuView
            },
            {
                path: "/create-productdetail",
                name: "CreateProductdetail",
                component: CreateProductDetail,
            },
            {
                path: "/edit-productdetail/:id",
                name: "EditProductDetail",
                component: EditProductDetail,
            },

            {
                path: "/product-configuration-create/:id",
                name: "ProductConfigurationCreate",
                component: ProductConfigurationCreate,
            },

            {
                path: "/product-filtration-create/:id",
                name: "ProductFiltration",
                component: ProductFiltration,
            },

            {
                path: "/attribute",
                name: "AttributeView",
                component: AttributeView,
            },
            {
                path: "/create-attribute",
                name: "CreateAttribute",
                component: CreateAttribute,
            },
            {
                path: "/edit-attribute/:id",
                name: "EditAttribute",
                component: EditAttribute,
            },

            {
                path: "/attributetype",
                name: "AttributeTypeView",
                component: AttributeTypeView,
            },
            {
                path: "/create-attributetype",
                name: "CreateAttributeType",
                component: CreateAttributeType,
            },
            {
                path: "/edit-attributetype/:id",
                name: "EditAttributeType",
                component: EditAttributeType,
            },

            {
                path: "/filter",
                name: "FilterView",
                component: FilterView,
            },
            {
                path: "/create-filter",
                name: "CreateFilter",
                component: CreateFilter,
            },
            {
                path: "/edit-filter/:id",
                name: "EditFilter",
                component: EditFilter,
            },

            {
                path: "/filtertype",
                name: "FilterTypeView",
                component: FilterTypeView,
            },
            {
                path: "/create-filtertype",
                name: "CreateFilterType",
                component: CreateFilterType,
            },
            {
                path: "/edit-filtertype/:id",
                name: "EditFilterType",
                component: EditFilterType,
            },
            {
                path: "/all-orders",
                name: "AllOrder",
                component: AllOrder,
            },
            {
                path: "/pending-orders",
                name: "PendingOrder",
                component: PendingOrder,
            },
            {
                path: "/closed-orders",
                name: "ClosedOrder",
                component: ClosedOrder,
            },
            {
                path: "/moved-orders",
                name: "MovedOrder",
                component: MovedOrder,
            },
            {
                path: "/refund-orders",
                name: "RefundOrder",
                component: RefundOrder,
            },
            {
                path: "/view-order/:id",
                name: "ViewOrder",
                component: ViewOrder,
            },
            {
                path: "/pending-products",
                name: "PendingProduct",
                component: PendingProduct,
            },
            {
                path: "/pending-products-details/:id",
                name: "PendingProductDetail",
                component: PendingProductDetail,
            },
        ],
    },

    {
        path: "/",
        beforeEnter: AuthGuard,
        name: "UserView",
        component: UserLayout,
        redirect: "/",
        children: [
            {
                path: "/forgot-password",
                name: "ForgotPassword",
                component: ForgotPassword
            },
            {
                path: '/reset-password/:token',
                name: "ResetPassword",
                component: ResetPassword
            },
            {
                path: "/data-entry-tips-view",
                name: "DataEntryTipsView",
                component: DataEntryTipsView
            },
            {
                path: "/barcode-export/:id",
                name: "BarcodeExport",
                component: BarcodeExport,
            },
            {
                path: "/barcode-number/:id",
                name: "BarcodeNumber",
                component: BarcodeNumber
            },
            {
                path: "/data-entry-create-tips",
                name: "DataEntryCreateTips",
                component: DataEntryCreateTips,
            },
            {
                path: "/data-entry-edit-tips/:id",
                name: "DataEntryEditTips",
                component: DataEntryEditTips
            },
            {
                path: "/about-us",
                name: "AboutView",
                component: AboutView
            },
            {
                path: "/contact",
                name: "ContactView",
                component: ContactView
            },
            {
                path: "/bazar-view",
                name: "BazarView",
                component: BazarView
            },
            {
                path: "/customise-design",
                name: "CustomiseDesign",
                component: CustomiseDesign
            },
            {
                path: "/brand-discounts",
                name: "BrandDiscount",
                component: BrandDiscount
            },
            {
                path: "/home-bundle-view/:id",
                name: "HomeBundleView",
                component: HomeBundleView
            },
            {
                path: "/prod-admin-export/:id",
                name: "SuperAdminExport",
                component: SuperAdminExport,
            },
            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy,
            },
            {
                path: "/login-callback",
                name: "LoginCallback",
                component: LoginCallback,
            },
            {
                beforeEnter: SuperUserAffiliate,
                path: "/user-affiliate-order/:id",
                name: "UserAffiliateOrder",
                component: UserAffiliateOrder,
            },
            {
                // beforeEnter: OrderManagementData,
                path: "/order-management-print/:id",
                name: "OrderManagementPrint",
                component: OrderManagementPrint,
            },
            {
                path: "/brand-view/:id",
                name: "HomeBrandView",
                component: HomeBrandView,
            },
            {
                path: "/user-profile",
                name: "UserProfile",
                component: UserProfile,
            },
            {
                path: "/",
                name: "Product",
                component: UserProductView,
            },
            {
                beforeEnter: superadminGuard,
                path: "/export-invoice/:id",
                name: "ExportInvoice",
                component: ExportInvoice,
            },
            {
                path: "/export-invoice-return/:id",
                name: "ExportInvoiceReturn",
                component: ExportInvoiceReturn,
            },
            {
                beforeEnter: AffiliateGuard,
                path: "/affiliate-order-details/:id",
                name: "AffiliateOrderDetails",
                component: AffiliateOrderDetails,
            },
            {
                path: "/subcategory-view/:id",
                name: "SubCategory",
                component: SubCategory,
            },
            {
                path: "/grand-bazar/:id",
                name: "GrandBazar",
                component: GrandBazar,
            },
            {
                path: "/childcategory-view/:id",
                name: "ChildCategory",
                component: ChildCategory,
            },

            {
                path: "/productlist-view/:id",
                name: "ProductList",
                component: ProductList,
            },

            {
                path: "/productdetail-view/:id",
                name: "ProductDetail",
                component: ProductDetail,
                beforeEnter: (to, from, next) => {
                    window.scrollTo(0, 0);
                    next();
                },
            },

            {
                path: "/allCategories",
                name: "AllCategories",
                component: AllCategories,
            },

            {
                path: "/categories/:id",
                name: "Categories",
                component: Categories
            },

            {
                path: "/productsale/:id",
                name: "ProductSaleView",
                component: ProductSaleView,
            },

            {
                path: "/products-search",
                name: "ProductSearch",
                component: ProductSearch,
            },

            {
                path: "/addtocart-view",
                name: "AddToCart",
                // beforeEnter: isAuthGuard,
                component: AddToCart,
                // meta: { requiresAuth: true },
            },

            {
                path: "/addtofavorite-view",
                name: "AddToFavorite",
                beforeEnter: isAuthGuard,
                component: AddToFavorite,
                meta: {requiresAuth: true},
            },
        ],
    },

    {path: "/:catchAll(.*)", component: NotFound},

    {
        path: "/login",
        name: "Login",
        beforeEnter: AuthGuard,
        component: LoginView,
    },

    {
        path: "/register",
        name: "register",
        beforeEnter: AuthGuard,
        component: RegisterView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    base: "/", // Ensure base is set to root level
    routes,

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        } else {
            return {top: 0, behavior: 'smooth'};
        }
    },

});

function superadminGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 1) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function jardeAdminGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 25) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function descriptionAdminGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 26) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function designAdminGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 27) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function finalAdminGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 28) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}


function prodAdminGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 3) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function targetChangeGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 50) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function AccountingChangeGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 52) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function QuantityGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 70) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}
    function BoxesGuard(to, from, next) {
        const store = useUserStore();
        // Access the data from the store
        const user = store?.user;
        const role = user?.roles;
        if (role === 69) {
            // User has the "superadmin" role, allow access to the route
            next();
        } else {
            // User does not have the "superadmin" role, redirect to another page or show an error message
            next("/"); // Redirect to the NotFound component
        }
    }

    function diffuserFormGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 60) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}


function insertedDataGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 51) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function productsNewGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (role === 30) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}


function SuperAffiliateGuard(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;
    if (role === 6) {
        next();
    } else {
        next("/");
    }
}

function OrderManagementData(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;
    if (role === 8) {
        next();
    } else {
        next("/");
    }
}


// function OrderAdminGuard(to, from,next){

// }

function isAuthGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    if (
        role === 0 ||
        role === 1 ||
        role === 2 ||
        role === 3 ||
        role === 4 ||
        role === 5 ||
        role === 6 ||
        role === 10 ||
        role === 9 ||
        role === 8
    ) {
        // User has one of the allowed roles, allow access to the route
        next();
    } else {
        // User does not have one of the allowed roles, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function AuthGuard(to, from, next) {
    const userStore = useUserStore(); // Get access to your user store
    const isLoggedIn = userStore.getUser() !== null; // Assuming you have a method to check if the user is logged in

    if (isLoggedIn) {
        // If the user is already logged in, proceed to the route
        next();
    } else {
        // If the user is not logged in, perform the login process
        axiosInstance
            .get("api/userinfo")
            .then((response) => {
                if (response.data.roles != null) {
                    // Update the user data in your user store
                    userStore.setUser(response.data);
                    // console.log(response.data);

                    if (response.data.roles !== null) {
                        next(); // Proceed to the route after updating the user data
                    }
                } else {
                    next(); // Proceed to the route if user data is not available or not valid
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    // Handle validation errors if needed
                    this.validationErrors = error.response.data.errors;
                }
            });
    }
}

/*if (CheckToken()) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;
    // Do something with the user data

    if (role !== null) {
      // User is authenticated, allow access to the route
      next();
    } else {
      // User is not authenticated, redirect to another page or show an error message
      next("/");
    }
  }*/

/*function CheckToken() {
  if (this.$cookies.get("token")) {
    axiosInstance
      .post(`api/me`)
      .then((response) => {
        const userStore = useUserStore();
        userStore.setUser(response.data.user);
        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          this.validationErrors = error.response.data.errors;
        }
      });
  }
  return false;
}
}*/

function AffiliateGuard(to, from, next) {
    const store = useUserStore();
    // Access the data from the store
    const user = store?.user;
    const role = user?.roles;

    if (role === 4) {
        // User has the "superadmin" role, allow access to the route
        next();
    } else {
        // User does not have the "superadmin" role, redirect to another page or show an error message
        next("/"); // Redirect to the NotFound component
    }
}

function SuperUserAffiliate(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;

    if (role === 6) {
        next();
    } else {
        next("/");
    }
}

function AdminExportResultss(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;

    if (role === 9 || role === 20) {
        next();
    } else {
        next("/");
    }
}

function DataEntryAdmin(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;

    if (role === 7) {
        next();
    } else {
        next("/");
    }
}

function SuperAdminSec(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;

    if (role === 13) {
        next();
    } else {
        next("/");
    }
}

function StorePanelSec(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;

    if (role === 11) {
        next();
    } else {
        next("/");
    }
}

function StockPanelSec(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;

    if (role === 12) {
        next();
    } else {
        next("/");
    }
}


function SubAffiliateGuard(to, from, next) {
    const store = useUserStore();
    const user = store?.user;
    const role = user?.roles;

    if (role === 5) {
        next();
    } else {
        next("/");
    }
}

export default router;

<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Orders Panel</h3>
          <p>InvoiceID: {{ this.$route.params.id }}</p>
          <button class="btn btn-primary mt-4" @click="updateCheckedRows">
            Update Selected Rows
          </button>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Percentage</th>
              <th>Affiliate Percentage</th>
              <th>Price After Discount</th>
              <th>Total Commission</th>
              <th>Affiliate Commission</th>
              <th>Sub Affiliate Commission</th>
              <th>CheckBoxes</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="allOrders in allOrder" :key="allOrders.id">
              <th scope="row">{{ allOrders.id }}</th>
              <td>{{ allOrders.title }} - {{ allOrders.sku }}</td>
              <td>${{ allOrders.sale_price.toFixed(2) }}</td>
              <td>{{ allOrders.quantity }}</td>
              <td><input type="text" class="form-control" v-model="allOrders.percentage"/></td>
              <td><input type="text" class="form-control" v-model="allOrders.affiliate_percentage"/></td>
              <td v-if="allOrders.price_after_discount">${{ allOrders.price_after_discount.toFixed(2) }}</td>
              <td v-else>-</td>
              <td v-if="allOrders.total_commission">{{ allOrders.total_commission }}</td>
              <td v-else>-</td>
              <td v-if="allOrders.affiliate_commission">{{ allOrders.affiliate_commission }}</td>
              <td v-else>-</td>
              <td v-if="allOrders.sub_affiliate_commission">{{ allOrders.sub_affiliate_commission }}</td>
              <td v-else>-</td>
              <th>
                <label class="custom-checkbox">
                  <input type="checkbox" v-model="allOrders.checked"/>
                  <span class="checkmark"></span>
                </label>
              </th>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 20px;
  height: 20px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

</style>

<script>
import axiosInstance from "../../../axiosInstance";

export default {
  name: "AllOrder",
  data() {
    return {
      allOrder: [],
      selectAll: false, // To track "select all" checkbox state
    };
  },
  created() {
    this.orderLoad();
  },
  methods: {
    async orderLoad() {
      const page = `api/get-invoice-cart-edit/${this.$route.params.id}`;
      try {
        const {data} = await axiosInstance.get(page);
        this.allOrder = data.map(order => ({
          ...order,
          checked: false, // Initialize unchecked state for checkbox
          original_percentage: order.percentage, // Track original value
          original_affiliate_percentage: order.affiliate_percentage, // Track original value
          original_price_after_discount: order.price_after_discount, // Track original value
        }));
      } catch (error) {
        console.error("Error loading orders:", error);
      }
    },
    toggleAllRows() {
      this.selectAll = !this.selectAll;
      this.allOrder.forEach(order => {
        order.checked = this.selectAll;
      });
    },
    async updateCheckedRows() {
      const invoiceId = this.$route.params.id;
      const checkedOrders = this.allOrder.filter(order => order.checked);

      if (checkedOrders.length === 0) {
        alert("No rows selected for update!");
        return;
      }

      try {
        for (const order of checkedOrders) {
          const updatedValues = {};

          // Only update values if they have changed
          if (order.percentage !== order.original_percentage) {
            updatedValues.percentage = parseFloat(order.percentage);
          }
          if (order.affiliate_percentage !== order.original_affiliate_percentage) {
            updatedValues.affiliate_percentage = parseFloat(order.affiliate_percentage);
          }

          // Calculate price_after_discount based on target value
          if (order.target === 0) {
            updatedValues.price_after_discount =
                order.sale_price * order.quantity -
                (order.sale_price * order.quantity * updatedValues.percentage) / 100;
          } else {
            updatedValues.price_after_discount =
                order.sale_price * order.quantity - updatedValues.percentage;
          }

          // Skip the order if no actual changes
          if (Object.keys(updatedValues).length === 0) {
            console.log("No changes detected for order ID:", order.id);
            continue;
          }

          // Send the update request for each checked order
          await axiosInstance.post("api/update-order", {
            cart_id: order.id,
            invoice_id: invoiceId,
            updated_values: updatedValues,
          });

          this.orderLoad();
          // Update local state to reflect changes
          order.original_percentage = order.percentage;
          order.original_affiliate_percentage = order.affiliate_percentage;
          order.original_price_after_discount = order.price_after_discount;
          order.checked = false; // Uncheck row after update
        }

        alert("Selected rows updated successfully!");
      } catch (error) {
        console.error("Error updating orders:", error);
      }
    }

  },
};
</script>
<template>
  <div v-if="showSuccessMessage" class="toast">
    Your order has been successfully placed!
  </div>

  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <a class="nav-link" data-widget="pushmenu" role="button" @click="toggleSidebar"
        :class="{ 'mobile-sidebar-open': isSidebarOpen && isMobileScreen }">
        <i class="fas fa-bars"></i>
      </a>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Home</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Contact</router-link>
      </li>
    </ul>


  </nav>

  <!-- Main Sidebar Container -->
  <aside style="margin-top:150px !important" class="main-sidebar"
    :class="{ 'sidebar-hidden': !isSidebarOpen, 'sidebar-mobile': !isSidebarOpen && isMobileScreen }">

    <!-- Brand Logo -->
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2" style="background-color: black; font-size: 12pt;">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->

          <li class="nav-item">
            <router-link to="/disabled-product" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Disabled Product
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/disabled-product-main" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Main Product
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/report-product-commission" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Commission Report
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/display-cost" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Cost Display
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/invoices-carts" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Invoices
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/daily-export" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Daily Export
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/edited-invoices" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Edited Invoices
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            &nbsp;
          </li>

          <li class="nav-item">
            &nbsp;
          </li>

          <li class="nav-item">
            &nbsp;
          </li>

          <li class="nav-item">
            &nbsp;
          </li>

          <li class="nav-item">
            &nbsp;
          </li>

          <li class="nav-item">
            &nbsp;
          </li>

          <li class="nav-item">
            <router-link to="/" class="nav-link" @click="logout"><i class="fas fa-sign-out-alt"
                style="color:white; margin-right: 8px; font-size: 8pt;"></i>Logout</router-link>
          </li>



        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import Cookies from 'js-cookie';
import router from "@/router";
import { RouterLink } from 'vue-router';
import axiosInstance from '../../../../axiosInstance';
import { useUserStore } from "../../../../store";
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import a locale if needed

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      publicKey: localStorage.getItem("name"),
      users: [],
      isSidebarOpen: true,
      isMobileScreen: false,
      showDropdown: false,
      timers: [], // Define the timers data property
      showDropdown3: false,
      showDropdown2: false,

    };
  },
  computed: {
    countdownTimers() {
      if (!Array.isArray(this.timers)) {
        return [];
      }

      return this.timers.map(timer => {
        const now = dayjs();
        const to = dayjs(timer.to_date);

        if (to.isBefore(now)) {
          return {
            ...timer,
            expired: true,
            timeRemaining: null,
          };
        } else {
          let diff = to.diff(now);
          const days = Math.floor(diff / (24 * 60 * 60 * 1000));
          diff -= days * 24 * 60 * 60 * 1000;
          const hours = Math.floor(diff / (60 * 60 * 1000));
          diff -= hours * 60 * 60 * 1000;
          const minutes = Math.floor(diff / (60 * 1000));
          diff -= minutes * 60 * 1000;
          const seconds = Math.floor(diff / 1000);

          return {
            ...timer,
            expired: false,
            timeRemaining: {
              days,
              hours,
              minutes,
              seconds,
            },
          };
        }
      });
    },
  },
  mounted() {
    this.checkMobileScreen(); // Check the screen size on component mount
    window.addEventListener('resize', this.checkMobileScreen); // Add a listener to update on window resize
    this.productSaleLoad();

    const isSuccess = this.$route.query.success === 'true';

    if (isSuccess) {
      // Display your success message here
      // You can set a variable in the data() object to control the visibility of the message
      this.showSuccessMessage = true; // For example, if you have a variable called 'showSuccessMessage'
    }
  },
  created() {
    this.productSaleLoad();
    this.timerLoad();
    this.startCountdownTimer();

  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileScreen); // Remove the listener on component unmount3
    clearInterval(this.countdownTimerInterval);

  },
  components: {
    RouterLink,
  },

  methods: {

    toggleDropdown3() {
      this.showDropdown3 = !this.showDropdown3;
    },

    toggleDropdown2() {
      this.showDropdown2 = !this.showDropdown2;
    },

    productSaleLoad() {
      const page = `api/get-code?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        this.users = data.affiliate;
      });
    },

    timerLoad() {
      const page = `api/get-time`;
      axiosInstance.get(page).then(({ data }) => {
        this.timers = data; // Assuming your API response contains timer data
      });
    },

    logout() {
      // localStorage.clear();
      this.publicKey = null; // Clear the publicKey property
      Cookies.remove('token');
      router.replace("/");
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    checkMobileScreen() {
      this.isMobileScreen = window.innerWidth < 768; // Set the isMobileScreen flag based on the window width
    },

    startCountdownTimer() {
      // Update the countdown timers every second
      this.countdownTimerInterval = setInterval(() => {
        this.updateCountdownTimers();
      }, 1000);
    },

    updateCountdownTimers() {
      const now = dayjs();
      this.timers.forEach(timer => {
        const to = dayjs(timer.to_date);
        if (!to.isBefore(now)) {
          const diff = to.diff(now);
          timer.timeRemaining = {
            days: Math.floor(diff / (24 * 60 * 60 * 1000)),
            hours: Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)),
            minutes: Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000)),
            seconds: Math.floor((diff % (60 * 1000)) / 1000),
          };
        }
      });
    },


  },
};
</script>


<style scoped>
.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.nav-item {
  position: relative;
}

.nav-item .nav-link {
  cursor: pointer;
}

.nav-item ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  min-width: 200px;
  padding: 10px;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  list-style: none;
}

.nav-item ul li {
  padding: 5px 0;
}

.nav-item ul li a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
}

.nav-item.active ul {
  display: block;
}

.sidebar-hidden {
  display: none;
}

.sidebar-mobile {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
}

@media (max-width: 767px) {
  .sidebar-mobile {
    padding-left: 50px;
  }
}

.fa-bars {
  @media (max-width: 767px) {
    padding-left: 150px;
    position: fixed !important;
    /* Adjust the padding value as per your requirement */
    /* or use margin instead */
    /* margin-right: 10px; */
  }
}
</style>
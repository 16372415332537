<template>
  <div v-if="showSuccessMessage" class="toast">
    Your order has been successfully placed!
  </div>

  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <a class="nav-link" data-widget="pushmenu" role="button" @click="toggleSidebar"
        :class="{ 'mobile-sidebar-open': isSidebarOpen && isMobileScreen }">
        <i class="fas fa-bars"></i>
      </a>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Home</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Contact</router-link>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->
      <!-- <li class="nav-item">
        <a class="nav-link" data-widget="navbar-search" href="#" role="button">
          <i class="fas fa-search"></i>
        </a>
        <div class="navbar-search-block">
          <form class="form-inline">
            <div class="input-group input-group-sm">
              <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
              <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                  <i class="fas fa-search"></i>
                </button>
                <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li> -->

      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-comments"></i>
          <span class="badge badge-danger navbar-badge">3</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" class="dropdown-item">
            <div class="media">
              <img src="admin_assets/dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Brad Diesel
                  <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">Call me whenever you can...</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <div class="media">
              <img src="admin_assets/dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  John Pierce
                  <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">I got your message bro</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <div class="media">
              <img src="admin_assets/dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Nora Silvester
                  <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">The subject goes here</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Message</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li> -->
    </ul>
  </nav>
  Your Unique Code is : {{ users.unique_code }}


  <!-- Main Sidebar Container -->
  <aside style="margin-top:150px !important" class="main-sidebar"
    :class="{ 'sidebar-hidden': !isSidebarOpen, 'sidebar-mobile': !isSidebarOpen && isMobileScreen }">

    <!-- Brand Logo -->
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2" style="background-color: black; font-size: 12pt;">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->

          <li class="nav-item">
            <router-link to="affiliate-user" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Sub Affiliate
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="user-affiliate" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Client
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <!-- <li class="nav-item">
            <router-link to="user-discount" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                User Discount
              </p>
            </router-link>
          </li> -->

          <li class="nav-item">
            <router-link to="make-order" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Make Order
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="affiliate-cart" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Cart
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="affiliate-product-discount" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Products Sale <span class="badge badge-danger">New</span>
              </p>

              <div v-for="(timer, index) in countdownTimers" :key="index">
                <div class="mt-2 badge badge-danger pr-4">
                  <span v-if="timer.timeRemaining">{{ timer.timeRemaining.days }} day </span>
                  <span v-if="timer.timeRemaining">{{ timer.timeRemaining.hours }} hours </span>
                  <span v-if="timer.timeRemaining">{{ timer.timeRemaining.minutes }} minutes </span>
                  <span v-if="timer.timeRemaining">{{ timer.timeRemaining.seconds }} seconds </span>
                </div>
              </div>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="affiliate-revenue" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                All Orders
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="sub-invoices" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Invoices
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/aff-pdf" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Pdf
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/affiliate-brand" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Brands
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/affiliate-address" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Address
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/affiliate-bundles" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Bundles
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/user-gift" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Gift
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/affiliate-product-rate" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Product Rate
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

<!--          <li class="nav-item">-->
<!--            <router-link to="/invoices-update" class="nav-link">-->
<!--              <i class="nav-icon fas fa-th"></i>-->
<!--              <p>-->
<!--                Carts Update-->
<!--                &lt;!&ndash; <span class="right badge badge-danger">New</span> &ndash;&gt;-->
<!--              </p>-->
<!--            </router-link>-->
<!--          </li>-->

          <li class="nav-item">
            <router-link to="/" class="nav-link" @click="logout"><i class="fas fa-sign-out-alt"
                style="color:white; margin-right: 8px; font-size: 8pt;"></i>Logout</router-link>
          </li>



        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->

    <!-- Main content -->
    <!-- <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-6">
            <div class="small-box bg-info">
              <div class="inner">
                <h3>150</h3>

                <p>New Orders</p>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="small-box bg-success">
              <div class="inner">
                <h3>53<sup style="font-size: 20px">%</sup></h3>

                <p>Bounce Rate</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="small-box bg-warning">
              <div class="inner">
                <h3>44</h3>

                <p>User Registrations</p>
              </div>
              <div class="icon">
                <i class="ion ion-person-add"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="small-box bg-danger">
              <div class="inner">
                <h3>65</h3>

                <p>Unique Visitors</p>
              </div>
              <div class="icon">
                <i class="ion ion-pie-graph"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
        </div>

      </div>

    </section> -->

  </div>
</template>

<script>
import Cookies from 'js-cookie';
import router from "@/router";
import { RouterLink } from 'vue-router';
import axiosInstance from '../../../../axiosInstance';
import { useUserStore } from "../../../../store";
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import a locale if needed

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      publicKey: localStorage.getItem("name"),
      users: [],
      isSidebarOpen: true,
      isMobileScreen: false,
      showDropdown: false,
      timers: [], // Define the timers data property
    };
  },
  computed: {
    countdownTimers() {
      if (!Array.isArray(this.timers)) {
        return [];
      }

      return this.timers.map(timer => {
        const now = dayjs();
        const to = dayjs(timer.to_date);

        if (to.isBefore(now)) {
          return {
            ...timer,
            expired: true,
            timeRemaining: null,
          };
        } else {
          let diff = to.diff(now);
          const days = Math.floor(diff / (24 * 60 * 60 * 1000));
          diff -= days * 24 * 60 * 60 * 1000;
          const hours = Math.floor(diff / (60 * 60 * 1000));
          diff -= hours * 60 * 60 * 1000;
          const minutes = Math.floor(diff / (60 * 1000));
          diff -= minutes * 60 * 1000;
          const seconds = Math.floor(diff / 1000);

          return {
            ...timer,
            expired: false,
            timeRemaining: {
              days,
              hours,
              minutes,
              seconds,
            },
          };
        }
      });
    },
  },
  mounted() {
    this.checkMobileScreen(); // Check the screen size on component mount
    window.addEventListener('resize', this.checkMobileScreen); // Add a listener to update on window resize
    this.productSaleLoad();

    const isSuccess = this.$route.query.success === 'true';

    if (isSuccess) {
      // Display your success message here
      // You can set a variable in the data() object to control the visibility of the message
      this.showSuccessMessage = true; // For example, if you have a variable called 'showSuccessMessage'
    }
  },
  created() {
    this.productSaleLoad();
    this.timerLoad();
    this.startCountdownTimer();

  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileScreen); // Remove the listener on component unmount3
    clearInterval(this.countdownTimerInterval);

  },
  components: {
    RouterLink,
  },

  methods: {

    productSaleLoad() {
      const page = `api/get-code?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        this.users = data.affiliate;
      });
    },

    timerLoad() {
      const page = `api/get-time`;
      axiosInstance.get(page).then(({ data }) => {
        this.timers = data; // Assuming your API response contains timer data
      });
    },

    logout() {
      // localStorage.clear();
      this.publicKey = null; // Clear the publicKey property
      Cookies.remove('token');
      router.replace("/");
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    checkMobileScreen() {
      this.isMobileScreen = window.innerWidth < 768; // Set the isMobileScreen flag based on the window width
    },

    startCountdownTimer() {
      // Update the countdown timers every second
      this.countdownTimerInterval = setInterval(() => {
        this.updateCountdownTimers();
      }, 1000);
    },

    updateCountdownTimers() {
      const now = dayjs();
      this.timers.forEach(timer => {
        const to = dayjs(timer.to_date);
        if (!to.isBefore(now)) {
          const diff = to.diff(now);
          timer.timeRemaining = {
            days: Math.floor(diff / (24 * 60 * 60 * 1000)),
            hours: Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)),
            minutes: Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000)),
            seconds: Math.floor((diff % (60 * 1000)) / 1000),
          };
        }
      });
    },


  },
};
</script>


<style scoped>
.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.nav-item {
  position: relative;
}

.nav-item .nav-link {
  cursor: pointer;
}

.nav-item ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  min-width: 200px;
  padding: 10px;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  list-style: none;
}

.nav-item ul li {
  padding: 5px 0;
}

.nav-item ul li a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
}

.nav-item.active ul {
  display: block;
}

.sidebar-hidden {
  display: none;
}

.sidebar-mobile {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
}

@media (max-width: 767px) {
  .sidebar-mobile {
    padding-left: 50px;
  }
}

.fa-bars {
  @media (max-width: 767px) {
    padding-left: 150px;
    position: fixed !important;
    /* Adjust the padding value as per your requirement */
    /* or use margin instead */
    /* margin-right: 10px; */
  }
}
</style>
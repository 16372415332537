<template>
  <div v-if="showSuccessMessage" class="toast">
    Your order has been successfully placed!
  </div>

  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <a class="nav-link" data-widget="pushmenu" role="button" @click="toggleSidebar"
         :class="{ 'mobile-sidebar-open': isSidebarOpen && isMobileScreen }">
        <i class="fas fa-bars"></i>
      </a>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Home</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Contact</router-link>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">

    </ul>
  </nav>

  <nav class="main-header navbar navbar-expand navbar-dark navbar-dark text-center justify-content-center">
    <!-- &lt;!&ndash; Left navbar links &ndash;&gt; -->
    <ul class="navbar-nav">

      <li class="nav-item nav-item-coin text-white">

        <div class="row">
          <div class="col-6" v-if="total_calculated_price">
            Your Total : $ {{ total_calculated_price }}

          </div>

          <div class="col-6">

            <span class="nav-item text-white">
              <div v-if="message">{{ message }}</div>

              <div v-if="claimedCoupon">
                <h3>Coupon Details:</h3>
                <p>Coupon Code: {{ claimedCoupon.coupon_code }}</p>
                <p>Coupon Commission: {{ claimedCoupon.coupon_commission }}</p>
              </div>

              <div v-else>
    <button
        :disabled="!isButtonClickable"
        @click="claimCoupon"
        class="text-dark btn btn-primary bg-white"
    >
      Coupon (For All Items)
    </button>
    <button
        :disabled="!isButtonClickable"
        @click="claimSemiCoupon"
        class="text-dark btn btn-primary bg-white ms-3"
    >
      Coupon (For Specific Items)
    </button>
              </div>

            </span>
          </div>
        </div>

        <div v-if="currentCoinLevel">
          <!--          <h2>Level: {{ currentCoinLevel.level }}</h2>-->
          <!--          <p>Amount: {{ currentCoinLevel.amount }}</p>-->

          <div class="row">
            <div class="col-6">

              <img v-if="currentCoinImage" :src="'https://api.gocami.com/coins/' + currentCoinLevel.image"
                   alt="Coin Level Image" class="coin-flip-shine-pulse" width="140"/>

              <li class="nav-item text-white">
                <p style="font-size:14pt !important" dir="rtl">{{ currentMessage2 }}</p>
                <!-- &lt;!&ndash; Display the current message &ndash;&gt; -->
              </li>
            </div>
            <div class="col-6">
              <img v-if="currentCoinImage" :src="'https://api.gocami.com/coins/' + nextField.semi_image"
                   alt="Coin Level Image" width="140"/>

              <li class="nav-item text-white">
                <p style="font-size:14pt !important" dir="rtl">{{ currentMessage }}</p>
                <!-- &lt;!&ndash; Display the current message &ndash;&gt; -->
              </li>

            </div>
          </div>
          <!-- &lt;!&ndash; Display the coin image if available &ndash;&gt; -->

          <span class="text-white">
        <span style="font-size: 12pt !important" dir="rtl">الملهمة</span> : {{
              affiliateName.first_name
            }} {{ affiliateName.middle_name }} {{ affiliateName.last_name }}
      </span>

        </div>

        <div v-else>
          <p>No suitable coin level found.</p>
        </div>

      </li>

    </ul>

  </nav>

  <!-- Main Sidebar Container -->
  <aside style="margin-top:150px !important" class="main-sidebar"
         :class="{ 'sidebar-hidden': !isSidebarOpen, 'sidebar-mobile': !isSidebarOpen && isMobileScreen }">

    <!-- Brand Logo -->
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2" style="background-color: black; font-size: 16pt;">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->


          <li class="nav-item">
            <router-link to="sub-affiliate-order" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Make Order
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="sub-affiliate-cart" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Cart
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/user-cart-gift" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Cart Gift
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>


          <li class="nav-item">
            <router-link to="/sub-affiliate-bundle" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Bundle
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/sub-user-gift" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                User Gift
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/sub-aff-rate" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Product Rate
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/product-medals" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Product Medal
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/bazar-products" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Bazar
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/sub-affiliate-commission" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Commission
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

<!--          <li class="nav-item">-->
<!--            <router-link to="/sub-affiliate-invoice-update" class="nav-link">-->
<!--              <i class="nav-icon fas fa-th"></i>-->
<!--              <p>-->
<!--                Cart Edit-->
<!--                &lt;!&ndash; <span class="right badge badge-danger">New</span> &ndash;&gt;-->
<!--              </p>-->
<!--            </router-link>-->
<!--          </li>-->


          <li class="nav-item">
            <router-link to="/" class="nav-link" @click="logout"><i class="fas fa-sign-out-alt"
                                                                    style="color:white; margin-right: 8px; font-size: 8pt;"></i>Logout
            </router-link>
          </li>


        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

</template>

<script>
import Cookies from 'js-cookie';
import router from "@/router";
import {RouterLink} from 'vue-router';
import axiosInstance from '../../../../axiosInstance';
import {useUserStore} from "../../../../store";
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import a locale if needed

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      affiliateName: [],
      currentMessage2: '',
      currentMessage: '',
      coinMessage: [],
      message: '', // Feedback message to display to the user
      coupon: null, // Store the coupon details if created successfully
      publicKey: localStorage.getItem("name"),
      users: [],
      isSidebarOpen: true,
      isMobileScreen: false,
      showDropdown: false,
      timers: [], // Define the timers data property
      cartsForMonth: [],
      total: 0,
      currentCoinLevel: null, // Coin level object
      currentCoinImage: null, // Coin image
      nextField: [],
      getCoupon: [],
      claimedCoupon: null,
      total_calculated_price: [],
      isButtonClickable: false
    };
  },
  computed: {
    countdownTimers() {
      if (!Array.isArray(this.timers)) {
        return [];
      }

      return this.timers.map(timer => {
        const now = dayjs();
        const to = dayjs(timer.to_date);

        if (to.isBefore(now)) {
          return {
            ...timer,
            expired: true,
            timeRemaining: null,
          };
        } else {
          let diff = to.diff(now);
          const days = Math.floor(diff / (24 * 60 * 60 * 1000));
          diff -= days * 24 * 60 * 60 * 1000;
          const hours = Math.floor(diff / (60 * 60 * 1000));
          diff -= hours * 60 * 60 * 1000;
          const minutes = Math.floor(diff / (60 * 1000));
          diff -= minutes * 60 * 1000;
          const seconds = Math.floor(diff / 1000);

          return {
            ...timer,
            expired: false,
            timeRemaining: {
              days,
              hours,
              minutes,
              seconds,
            },
          };
        }
      });
    },
  },
  mounted() {
    this.fetchMessages2();
    this.fetchMessages(); // Fetch all messages on component mount

    if (this.total > 0) {
      this.coin(); // Fetch coin level with a valid `total`
    } else {
      console.warn("Total is 0, skipping coin level fetch.");
    }

    // this.coinTotal(); // Ensures `total` is calculated before calling `coin()`
    this.checkMobileScreen(); // Check the screen size on component mount
    window.addEventListener('resize', this.checkMobileScreen); // Add a listener to update on window resize
    this.productSaleLoad();

    const isSuccess = this.$route.query.success === 'true';

    if (isSuccess) {
      // Display your success message here
      // You can set a variable in the data() object to control the visibility of the message
      this.showSuccessMessage = true; // For example, if you have a variable called 'showSuccessMessage'
    }
  },
  created() {
    this.checkButtonAvailability();
    // this.getSubAffCoinTotal();
    this.productSaleLoad(); // OK
    this.timerLoad(); // OK
    this.startCountdownTimer(); // OK
    this.getCoinTotal(); // Fetches `cartsForMonth` and calculates `total`
    // this.coinLevel(); // OK
    this.getClaimedCouponCode();
    this.getAffiliateName();
  },

  beforeUnmount() {
    if (this.messageInterval) {
      clearInterval(this.messageInterval); // Clear the interval on component destruction
    }

    window.removeEventListener('resize', this.checkMobileScreen); // Remove the listener on component unmount3
    clearInterval(this.countdownTimerInterval);

  },
  components: {
    RouterLink,
  },

  methods: {

    checkButtonAvailability() {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      // Current month (25th)
      const currentMonthStart = new Date(currentYear, currentMonth, 25);

      // Next month (5th)
      const nextMonthEnd = new Date(currentYear, currentMonth + 1, 5);

      // Check if today is between the 25th of the current month and the 5th of the next month
      if (now >= currentMonthStart && now <= nextMonthEnd) {
        this.isButtonClickable = true;
      } else {
        this.isButtonClickable = false;
      }
    },


    getAffiliateName() {
      const page = `api/get-aff-name?user_id=${this.user?.id}`; // Ensure the correct endpoint
      axiosInstance.get(page).then(({data}) => {
        this.affiliateName = data.affiliateName; // Access correctly
      }).catch((error) => {
        console.error("Error fetching claimed coupon:", error); // Log errors
      });
    },

    fetchMessages() {
      const page = `api/get-sub-aff-message`; // Endpoint to fetch messages
      axiosInstance.get(page)
          .then(({data}) => {
            // Check if data.messages is an array
            if (Array.isArray(data.messages)) {
              this.messages = data.messages; // Store fetched messages
              this.startMessageInterval(); // Start changing messages
            } else {
              console.error('Invalid data structure:', data); // Error if not an array
            }
          })
          .catch((error) => {
            console.error('Error fetching messages:', error); // Handle fetch errors
          });
    },

    fetchMessages2() {
      const page = `api/get-sub-aff-message-2`; // Endpoint to fetch messages
      axiosInstance.get(page)
          .then(({data}) => {
            // Check if data.messages is an array
            if (Array.isArray(data.messages)) {
              this.messages2 = data.messages; // Store fetched messages
              this.startMessageInterval(); // Start changing messages
            } else {
              console.error('Invalid data structure:', data); // Error if not an array
            }
          })
          .catch((error) => {
            console.error('Error fetching messages:', error); // Handle fetch errors
          });
    },


    startMessageInterval() {
      this.updateMessage(); // Set initial message
      this.messageInterval = setInterval(this.updateMessage, 30000); // Change message every 30 seconds
    },
    updateMessage() {
      // Check if messages arrays have content, then select random message
      if (this.messages.length > 0 && this.messages2.length > 0) {
        const randomIndex1 = Math.floor(Math.random() * this.messages.length);
        const randomIndex2 = Math.floor(Math.random() * this.messages2.length);
        this.currentMessage = this.messages[randomIndex1].message; // Display a random message from messages
        this.currentMessage2 = this.messages2[randomIndex2].message; // Display a random message from messages2
      } else {
        this.currentMessage = 'No messages available'; // Fallback for empty message arrays
      }
    },
    // afterDiscount() {
    //   this.total = this.cartsForMonth.reduce((totalAfterDiscount, cart) => {
    //     if (cart.price_after_discount > 0) {
    //       totalAfterDiscount += cart.price_after_discount;
    //     } else {
    //       const salePrice = cart.sale_price * cart.quantity;
    //       const discount = salePrice * (cart.percentage / 100);
    //       totalAfterDiscount += salePrice - discount;
    //     }
    //     return totalAfterDiscount;
    //   }, 0);
    // },

    // Function to fetch carts for the month and update `total`
    getCoinTotal() {
      const endpoint = `api/get-sub-aff-coin?user_id=${this.user?.id}`;

      axiosInstance.get(endpoint)
          .then(({data}) => {
            this.cartsForMonth = data.cartsForMonth;
            this.total_calculated_price = data.total_calculated_price
            this.coin(); // Fetch the coin level
          })
          .catch((error) => {
            // Only log errors if they are not related to "no data" or a specific expected scenario
            if (error.response && error.response.status !== 404) {
              console.error("Error fetching cartsForMonth:", error);
            }
          });
    },

    async claimCoupon() {
      try {
        // Clear any previous message or coupon data
        this.message = '';
        this.coupon = null;

        // Send a POST request to claim the coupon
        const response = await axiosInstance.post('api/get-sub-aff-coupon', {user_id: this.user?.id});

        if (response.status === 200) {
          const data = response.data;
          this.getClaimedCouponCode();
          // Check if a coupon was successfully created
          if (data.coupon_code) {
            this.coupon = data; // Store the coupon details
            this.message = 'Coupon successfully claimed!';
          } else {
            this.message = data.message; // Could be the case when total price is not high enough
          }
        }
      } catch (error) {
        console.error('Error claiming coupon:', error);
        this.message = 'An error occurred while claiming the coupon.';
      }
    },

    async claimSemiCoupon() {
      try {
        // Clear any previous message or coupon data
        this.message = '';
        this.coupon = null;

        // Send a POST request to claim the coupon
        const response = await axiosInstance.post('api/get-sub-aff-semi-coupon', {user_id: this.user?.id});

        if (response.status === 200) {
          const data = response.data;
          this.getClaimedCouponCode();
          // Check if a coupon was successfully created
          if (data.coupon_code) {
            this.coupon = data; // Store the coupon details
            this.message = 'Coupon successfully claimed!';
          } else {
            this.message = data.message; // Could be the case when total price is not high enough
          }
        }
      } catch (error) {
        console.error('Error claiming coupon:', error);
        this.message = 'An error occurred while claiming the coupon.';
      }
    },


    // Function to fetch the coin level based on `total`
    coin() {
      console.log("Total before fetching coin level:", this.total_calculated_price); // Double-check `total`
      const endpoint = `api/get-coin-level/${this.total_calculated_price}`;

      axiosInstance.get(endpoint)
          .then((response) => {
            console.log("Response from server:", response.data); // Log the response data
            const {coinLevel, semiImage} = response.data;

            if (coinLevel) {
              this.currentCoinLevel = coinLevel;
              this.currentCoinImage = coinLevel.image;
              this.nextField = {semi_image: semiImage}; // Ensure correct structure
            } else {
              console.warn("No suitable coin level found.");
            }
          })
          .catch((error) => {
            console.error("Error fetching coin level:", error); // Handle errors
          });
    },

    productSaleLoad() {
      const page = `api/get-code?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({data}) => {
        this.users = data.affiliate;
      });
    }
    ,

    // coinMessage() {
    //   const page = `api/get-sub-aff-message`;
    //   axiosInstance.get(page).then(({data}) => {
    //     this.message = data.message;
    //     // this.afterDiscount();
    //   });
    // }
    // ,

    coinLevel() {
      const page = `api/get-sub-aff-coin?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({data}) => {
        this.$coins = data.$coins;
        // this.afterDiscount();
      });
    }
    ,

    getClaimedCouponCode() {
      const page = `api/get-claimed-coupon?user_id=${this.user?.id}`; // Ensure the correct endpoint
      axiosInstance.get(page).then(({data}) => {
        console.log("Claimed coupon data:", data); // Log the response to check
        this.claimedCoupon = data.claimedCoupon; // Access correctly
      }).catch((error) => {
        console.error("Error fetching claimed coupon:", error); // Log errors
      });
    },

    timerLoad() {
      const page = `api/get-time`;
      axiosInstance.get(page).then(({data}) => {
        this.timers = data; // Assuming your API response contains timer data
      });
    }
    ,

    logout() {
      // localStorage.clear();
      this.publicKey = null; // Clear the publicKey property
      Cookies.remove('token');
      router.replace("/");
    }
    ,

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    }
    ,
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
    ,
    checkMobileScreen() {
      this.isMobileScreen = window.innerWidth < 768; // Set the isMobileScreen flag based on the window width
    }
    ,

    startCountdownTimer() {
      // Update the countdown timers every second
      this.countdownTimerInterval = setInterval(() => {
        this.updateCountdownTimers();
      }, 1000);
    }
    ,

    updateCountdownTimers() {
      const now = dayjs();
      this.timers.forEach(timer => {
        const to = dayjs(timer.to_date);
        if (!to.isBefore(now)) {
          const diff = to.diff(now);
          timer.timeRemaining = {
            days: Math.floor(diff / (24 * 60 * 60 * 1000)),
            hours: Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)),
            minutes: Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000)),
            seconds: Math.floor((diff % (60 * 1000)) / 1000),
          };
        }
      });
    }
    ,


  }
  ,
}
;
</script>


<style scoped>
@keyframes flip {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  50% {
    transform: perspective(1000px) rotateY(180deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

@keyframes shine {
  0% {
    filter: brightness(100%);
  }

  50% {
    filter: brightness(150%);
    /* Shine effect */
  }

  100% {
    filter: brightness(100%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    /* Grow larger */
  }

  100% {
    transform: scale(1);
    /* Return to original size */
  }
}

.coin-flip-shine-pulse {
  animation: flip 2s linear infinite,
    /* Flip effect */ shine 2s ease-in-out infinite,
    /* Shine effect */ pulse 2s ease-in-out infinite;
  /* Pulse effect */
  transform-style: preserve-3d;
  transition: all 2s;
  /* Transition effect for smooth changes */
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.nav-item {
  position: relative;
}

.nav-item .nav-link {
  cursor: pointer;
}

.nav-item ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  min-width: 200px;
  padding: 10px;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  list-style: none;
}

.nav-item ul li {
  padding: 5px 0;
}

.nav-item ul li a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
}

.nav-item.active ul {
  display: block;
}

@media only screen and (min-width: 768px) {
  .nav-item-coin {
    margin-right: 250px !important;
  }
}

.sidebar-hidden {
  display: none;
}

.sidebar-mobile {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
}

@media (max-width: 767px) {
  .sidebar-mobile {
    padding-left: 50px;
  }
}

.fa-bars {
  @media (max-width: 767px) {
    padding-left: 150px;
    position: fixed !important;
    /* Adjust the padding value as per your requirement */
    /* or use margin instead */
    /* margin-right: 10px; */
  }
}
</style>